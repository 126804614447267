import React from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";

export default function InstallationSelector({ product, installation, onChange }) {
	const installationCharge = product.installationCharge || 0;
	const installationType = product.installation || "optional";
	const installationValue = installation ? "true" : "false";

	useEffect(() => {
		if (installationValue === "false" && installationType === "mandatory")
			onChange(true);
	}, [installationValue, installationType, onChange]);

	// Form our installation options.
	const options = [];
	if (installationType === "none") {
		options.push({
			label: "No installation option",
			value: "false"
		});
	} else if (installationType === "optional") {
		options.push({
			label: `With installation (+ £${(installationCharge / 100).toFixed(2)})`,
			value: "true"
		});
		options.push({
			label: "Without installation",
			value: "false"
		});
	} else if (installationType === "mandatory") {
		options.push({
			label: "Installation included",
			value: "true"
		});
	}

	return <select
		value={installation}
		onChange={e => onChange(e.target.value === "true" ? true : false)}
		disabled={options.length === 1}
	>
		{options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
	</select >
}

InstallationSelector.propTypes = {
	product: PropTypes.object,
	installation: PropTypes.bool,
	onChange: PropTypes.func
} 
