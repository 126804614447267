import { combineReducers } from "redux";
import initialState from "./initialState";
// Reducer imports.
import session from "./session";
import logger from "./logger";
import property from "./property";
import settings from "./settings";
import developer from "./developer";
import development from "./development";
import cart from "./cart";
import orders from "./orders";

// Export out the starting state.
export { initialState };

export default combineReducers({
    session,
	logger,
    property,
    settings,
	developer,
	development,
	cart,
	orders
});
