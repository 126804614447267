import React, { useContext, useEffect, useState, useMemo } from "react";
import { BrandContext } from "../..";
import {
	Button,
} from "../../assets";
import ProductDescription from "./ProductDescription";
import { useModal } from "../../assets/useModal"
import { Carousel } from "react-responsive-carousel";
import { helpCircle } from "../../icons";
import { ImgIcon, QuantitySelector } from "../../assets";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
	formatDescriptionData,
	getPackageVariant,
	getVariantPrice
} from "../../helpers";
import InstallationSelector from "./InstallationSelector";
import VariantPicker, { VariantOptionPicker } from "./VariantPicker";
import useSelectedQuantity from "../../helpers/hooks/useSelectedQuantity";

export default function ProductCard({
	product = { id: null },
	installed = false,
	disabled = false,
	onQuantityChange = null,
	maxQuantity,
	onHelp
}) {
	const brand = useContext(BrandContext);

	const [variant, setVariant] = useState(null);
	const [installation, setInstallation] = useState(false);

	const selected = useSelectedQuantity(product, variant);

	const isSingleSelect = maxQuantity === 1;

	// Make sure a variant is set.
	useEffect(() => {
		if (product && !variant) {
			if (product.variants && product.variants[0])
				setVariant(product.variants[0]);

			// Set variant if product is a package without any variant.
			if (product.isPackage && (!product.variants || !product.variants.length))
				setVariant(getPackageVariant(null, product));
		}
	}, [product, variant]);

	const imageSrc = useMemo(() => {
		let result = ((variant && variant.images && variant.images[0]) || (product.images && product.images[0])) || {};
		return result.url || "";
	}, [product, variant])

	const { setModal, openModal } = useModal();
	function productModal() {

		setModal(
			<div className="product-details">
				<div className="product-images-carousel-container">
					<Carousel
						className="product-images-carousel"
						showArrows={false}
						renderIndicator={false}
						statusFormatter={(current, total) => ``}
					>
						{Boolean(product && product.images) && product.images.map(item => {
							return <div key={item.id} className="product-image">
								<img src={item.url} alt={`${product.title}-${item.id}`} />
							</div>
						})}
					</Carousel>
				</div>
				<ProductDescription description={product.bodyHtml} />
			</div>,
			null,
			{ closable: true }
		);
		openModal();
	};

	if (!variant) return null;

	return (
		<div className="product-card">
			<div className="product-image">
				<img src={imageSrc} alt="product" />
			</div>
			<br />
			<div className="product-about">
				<div className="details">
					<div className="default-font large bold title" style={{ color: brand.color1 }}>{product.title}</div>
					<div className="default-font bold uppercase brand" style={{ color: brand.color1 }}>{product.brand ? product.brand.name : ""}</div>
					<div className="default-font description" dangerouslySetInnerHTML={{ __html: formatDescriptionData(product.bodyHtml) }} />
					<div className="description-fade"></div>

					{/* Configuration */}
					<div className="product-configuration">

						{/* Variants */}
						{Boolean(product.variants && product.variants.length > 1) && (
							product.options && product.options.length ?
								<VariantOptionPicker
									product={product}
									variant={variant}
									onChange={title => setVariant(product.variants.find(v => v.title === title))}
								/>
								:
								<VariantPicker
									product={product}
									variant={variant}
									onChange={title => setVariant(product.variants.find(v => v.title === title))}
								/>
						)}

						{/* Installation */}
						<div className="product-configuration-installation flexy">
							<label>Installation:</label>
							<InstallationSelector
								installation={installation}
								onChange={setInstallation}
								product={product}
							/>
						</div>

						{/* Price */}
						<div className="default-font bold price uppercase">
							£{((getVariantPrice(variant, product, 1, installation) / 100).toFixed(2))}
						</div>
					</div>


					{/* Message if the item is already added. */}
					{installed && <div className="default-font bold medium installed">
						You already have this product installed at your property.
					</div>}
				</div>
				<div className="action-button-container">

					{/* Single select button  */}
					{Boolean(!installed && product && product.title && onQuantityChange && isSingleSelect) && <Button
						disabled={disabled}
						className={selected ? "selected" : null}
						style={selected ? {
							backgroundColor: brand.color1,
							color: brand.color2
						} : {}}
						onClick={() => onQuantityChange(variant.title, selected ? 0 : 1, installation)}
					>
						{Boolean(selected) && <span style={{ marginRight: 5 }}>&#10004;</span>}
						<span className="align-center">
							{selected ? "Remove from basket" : "Add to basket"}
						</span>
					</Button>}

					{/* Multi select buttons */}
					{Boolean(product && product.title && onQuantityChange && !isSingleSelect)
						?
						selected === 0
							?
							<Button onClick={() => onQuantityChange(variant.title, 1, installation)}>
								Add to basket
							</Button>
							:
							<QuantitySelector
								value={selected}
								max={maxQuantity}
								onChange={quantity => onQuantityChange(variant.title, quantity, installation)}
							/>
						:
						null
					}

					<Button className="show-details" onClick={productModal} style={{ backgroundColor: brand.color1 }}>
						<span className="align-center">Full details</span>
					</Button>

					<Button style={{ backgroundColor: "#40c9b6" }} onClick={onHelp}>
						<ImgIcon
							style={{
								filter: "invert(1)",
								marginRight: "5px"
							}}
							cursor="pointer"
							className="align-center"
							src={helpCircle}
							size={16}
						/>
						<span className="align-center">Need help with this?</span>
					</Button>
				</div>
			</div>
		</div >
	)
}
