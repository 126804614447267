// import React, { useEffect, useContext, useRef, useState } from "react";
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { ImgIcon, AssetImage } from "../../assets";
import { cross } from "../../icons";
import { clearLookup } from "../../actions/property";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { logout } from "../../actions/session";
import { useModal } from "../../assets/useModal";
import LoginSignup from "../LoginSignup";
import { usePrevious } from "../../helpers";
import CartIcon from "./CartIcon";

export default function Navbar(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const { setModal, openModal, closeModal } = useModal();
	const { user, links } = useSelector(state => ({
		user: state.session.user,
		links: state.settings.links || []
	}), shallowEqual);
	const [y, setY] = useState(window.scrollY);

	const handleNavigation = useCallback(
		e => {
			const window = e.currentTarget;
			setY(window.scrollY);
		}, []
	);

	useEffect(() => {
		window.addEventListener("scroll", (e) => handleNavigation(e), true);

		return () => { // return a cleanup function to unregister our function since its gonna run multiple times
			window.removeEventListener("scroll", (e) => handleNavigation(e), true);
		};
	}, [handleNavigation]);

	const isLogged = Boolean(user);
	const wasLogged = usePrevious(isLogged);

	useEffect(() => {
		if (isLogged && !wasLogged && openModal) {
			closeModal();
			setTimeout(() => {
				setModal(null);
			}, 200);
		}
	}, [openModal, closeModal, isLogged, wasLogged, setModal]);

	const navigateToSelect = () => {
		dispatch(clearLookup());
		history.push("/");
	}

	const checkIfAuthenticated = () => {
		setModal(
			<LoginSignup />,
			null,
			{ closable: true, clearOnClose: true, className: 'auth-modal', contentClass: 'inner-content' }
		);
		openModal();
	}

	return <div className={`navbar ${y < 40 ? '' : 'navbar-container-reduce-height'}`}>
		<section className="top-bar" id="upper-header">
			<div className="top-div">
				<div className="left-div">
					<AssetImage src={'./icon/menu.svg'} onClick={props.handleDrawerOpen} className="toggle-menu"></AssetImage>
				</div>
				<div>
					<img className='navPad pointer' onClick={navigateToSelect} src={require('../../assets/icon/logo.svg')} alt="logo" />
					{/* <p><span style={{ color: "blue" }} className='navPad'>AVANDE</span>CONNECT</p> */}
				</div>
				<div>
					<div className="right-tab">
						{
							user
								?
								<>
									<Link to="/account" className="bottom-bar-link text-font">{user.email}</Link>
									<div className="vertical-divider"></div>
									<p
										className="text-font pointer"
										onClick={() => {
											dispatch(logout());
											history.push("/");
										}}
									>Sign Out</p>
									<div className="vertical-divider"></div>
								</>
								:
								<>
									<p className="text-font pointer" onClick={checkIfAuthenticated}>Sign In</p>
									<div className="vertical-divider"></div>
								</>
						}
					</div>
					<div>
						<CartIcon />
					</div>
				</div>
			</div>
		</section>
		<section className={`bottom-bar bottom-pad ${y < 40 ? '' : 'navbar-fade-exit'}`} id="lower-header">
			<LinkSet links={links} />
		</section>
		<section className={`sidenav-container sidenav-hide ${props.isDrawerOpen ? 'show-sidenav' : 'hide-sidenav'}`} onClick={props.handleDrawerClose}>
			<div className={'drawer-close-button'}>
				<p>Menu</p>
				<ImgIcon
					className="toggle-menu"
					size={24}
					src={cross}
					cursor="pointer"
					onClick={props.handleDrawerOpen}
				/>
			</div>
			<div className="menu-div">
				<div>
					<LinkSet links={links} />
				</div>
				<div className="bottom-margin">
					{user
						? <>
							<p>{user.email}</p>
							<p onClick={() => dispatch(logout())}>Sign Out</p>
						</>
						: <p onClick={checkIfAuthenticated}>Sign In</p>
					}
					<Link to="/checkout" className="bottom-bar-link">Checkout</Link>
				</div>
			</div>
		</section>
	</div>
};

function LinkSet({ links }) {
	return links.map(link => {
		const isInternal = link.href.startsWith("/");
		const inner = <p className="link-text">{link.label}</p>;
		return isInternal ?
			<Link
				className="bottom-bar-link"
				key={link.id}
				to={link.href}
			>{inner}</Link>
			:
			<a
				className="bottom-bar-link"
				key={link.id}
				href={link.href}
			>{inner}</a>
	});
}
