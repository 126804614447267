import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoginSignup from "../components/LoginSignup";
import { parseQueryString } from "../helpers";

function LoginView() {

	const history = useHistory();
	const user = useSelector(state => state.session.user);
	const [ready, setReady] = useState(false);

	// Getting data form query string
	const query = useMemo(() => {
		return parseQueryString(window.location.search)
	}, []);

	useEffect(() => {
		if (user) {
			if (query.return_to) {
				history.push(query.return_to);
			} else {
				history.push("/account");
			}
		}
	}, [history, query.return_to, user]);

	useEffect(() => {
		setReady(true);
	}, []);

	return ready ? <>
		<Helmet title="Login" />
		<div className="auth-view">
			<div className="login-container">
				<LoginSignup onLoginSuccess={e => history.push("/account")} />
			</div>
		</div>
	</> : null;
}

export default withRouter(LoginView);
