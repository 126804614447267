import { initialState } from ".";
import { PROPERTY_LOOKUP_START, PROPERTY_LOOKUP_FAIL, PROPERTY_LOOKUP_SUCCESS, LOOKUP_CLEAR } from "../actions/property";

export default function (state = initialState.property, action) {
	switch (action.type) {

		case PROPERTY_LOOKUP_START:
			return {
				...state,
				lookupError: null,
				loading: true
			}
		case PROPERTY_LOOKUP_FAIL:
			return {
				...state,
				lookupError: action.error,
				loading: false
			}
		case PROPERTY_LOOKUP_SUCCESS:
			return {
				...state,
				loading: false,
				...action.property
			}

		case LOOKUP_CLEAR:
			return {
				...state,
				uid: null
			};

		default:
			return state
	}
}
