import React, { useState, useEffect, useRef, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { HeroImage } from "../assets";
import { Spinner, SpinnerWrapper, Button } from "../assets";
import ContactSection from "./ContactSection";
import { propertyLookup } from "../actions/property";
import PropertyLookupField from "../assets/PropertyLookup";
import { parseQueryString } from "../helpers";
import { developerIcon, codeIcon, noCodeIcon, codeIconGrey, noCodeIconGrey, developerIconGray } from "../icons";
import VerticalExpander from "../assets/VerticalExpander";

export default function HomeView() {

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	// Getting data form query string
	const query = useMemo(() => {
		return parseQueryString(window.location.search)
	}, []);

	const [pageEntered, setPageEntered] = useState(false);
	const [code, setCode] = useState(query.code ? query.code : "");

	const { property, developer, development, settings } = useSelector(state => ({
		property: state.property,
		developer: state.developer,
		development: state.development,
		settings: state.settings || {}
	}));

	const codeRef = useRef(null);
	const postcodeRef = useRef(null);
	const developerRef = useRef(null);

	const [lookupValue, setLookupValue] = useState(null);

	useEffect(() => {
		// Scrolling to the top of the page on mount
		const scrollToTop = event => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		};
		scrollToTop();
		window.addEventListener('load', scrollToTop);
		return () => window.removeEventListener('load', scrollToTop);
	}, []);

	useEffect(() => {
		const to = setTimeout(() => {
			setPageEntered(true);
		}, [300]);
		return () => {
			clearTimeout(to);
		}
	}, []);

	/**
	 * Use the available entities to create the URL we should be on.
	 */
	useEffect(() => {
		let path = "/";
		if (developer.uid) {
			path += `developer/${developer.uid}`;
			if (development.uid) {
				path += `/${development.uid}`;
				if (property.uid) {
					path += `/${property.uid}`;
				}
			}
		}
		if (path !== location.pathname) history.push(path.toLowerCase());
	}, [history, developer.uid, development.uid, property.uid, location.pathname]);

	if (settings.loading) return <SpinnerWrapper><Spinner color="#29348f" size={150} /></SpinnerWrapper>

	return <div className="home-view" style={{
		opacity: pageEntered ? 1 : 0,
		transition: "opacity 0.5s ease"
	}}>
		<Helmet title="Home" />
		<HeroImage src={settings.heroImage ? settings.heroImage.url : ""} overlay="#271f88">
			<b className="absolute-center heroText">SELECT</b>
			{/* <div className="overflowing text-center">{settings.heroImageBoxText}</div> */}
		</HeroImage>
		<div className="button-row">
			<button onClick={() => {
				let { top } = codeRef.current.getBoundingClientRect();
				window.scrollTo({ top: top + window.scrollY, behavior: "smooth" });
			}}>
				<div className="inner">
					<img className="button-icon" src={codeIcon} alt="developer" />
					<div className="button-label">I have my property code</div>
				</div>
			</button>
			<button onClick={() => {
				let { top } = postcodeRef.current.getBoundingClientRect();
				window.scrollTo({ top: top + window.scrollY, behavior: "smooth" });
			}}>
				<div className="inner">
					<img className="button-icon" src={noCodeIcon} alt="no-code" />
					<div className="button-label">I don't have a code</div>
				</div>
			</button>
			<button onClick={() => {
				let { top } = developerRef.current.getBoundingClientRect();
				window.scrollTo({ top: top + window.scrollY, behavior: "smooth" });
			}}>
				<div className="inner">
					<img className="button-icon" src={developerIcon} alt="no-code" />
					<div className="button-label">I'm a property developer</div>
				</div>
			</button>
		</div>
		<br />
		<section>
			<div className="section-content text-center">
				<div dangerouslySetInnerHTML={{ __html: settings.selectPageText }} />
				<br />
				<br />
			</div>
		</section>
		<section>
			<div ref={codeRef} className="section-content text-center">
				<br />
				<img className="code-section-icon" src={codeIconGrey} alt="code icon" />
				<div
					className="select-page-text"
					dangerouslySetInnerHTML={{ __html: settings.selectPagePropertyCodeText }}
				/>
				<br />
				<form className="property-code" onSubmit={e => {
					e.preventDefault();
					dispatch(propertyLookup(code));
				}}>
					<input
						className="default-font medium"
						type="text"
						placeholder="Enter your property's code"
						value={code}
						autoFocus={true}
						onChange={e => setCode(e.target.value)}
					/>
					<Button
						loading={property.loading}
						disabled={!Boolean(code) || property.loading}
						className="default-font medium"
						type="submit"
					>
						Enter
					</Button>
				</form>
				{Boolean(property.lookupError) && <div className="error">
					{property.lookupError}
				</div>}
			</div>
		</section>
		<section style={{ zIndex: 1 }}>
			<br />
			<br />
			<br />
			<div ref={postcodeRef} className="section-content text-center">
				<br />
				<img className="code-section-icon" src={noCodeIconGrey} alt="code icon" />
				<div
					className="select-page-text"
					dangerouslySetInnerHTML={{ __html: settings.selectPagePostCodeLookupText }}
				/>
				<br />
			</div>
			<VerticalExpander open={true}>
				{!lookupValue
					?
					<div className="section-content text-center">
						<PropertyLookupField onSelect={item => setLookupValue(item)} value={lookupValue} />
					</div>
					:
					<ContactSection property={lookupValue} onBack={() => setLookupValue(null)} />
				}
			</VerticalExpander>
		</section>
		<section>
			<br />
			<br />
			<br />
			<div ref={developerRef} className="section-content text-center">
				<br />
				<img className="code-section-icon" src={developerIconGray} alt="developer icon" />
				<div
					className="select-page-text"
					dangerouslySetInnerHTML={{ __html: settings.selectPageDeveloperText }}
				/>
				<br />
				<Button
					style={{
						margin: "0 auto"
					}}
					className="default-font medium"
				// onClick={() => {
				//     window.open("https://avandeconnect.com/pages/contact-1")
				// }}
				>
					<a href="https://avandeconnect.com/pages/contact-1" target="_self" className="anchor">Get in touch</a>
				</Button>
			</div>
		</section>
	</div>
}
