import axios from "axios";
import { API_ROOT } from "../constants";
import { errorResponse } from "../helpers";

export const DEVELOPER_LOOKUP_START = "DEVELOPER_LOOKUP_START";
export const DEVELOPER_LOOKUP_SUCCESS = "DEVELOPER_LOOKUP_SUCCESS";
export const DEVELOPER_LOOKUP_FAIL = "DEVELOPER_LOOKUP_FAIL";

export function developerLookup(uid) {
	return function (dispatch) {

		dispatch({
			type: DEVELOPER_LOOKUP_START,
			uid
		});

		axios.get(`${API_ROOT}/developers/${uid}`).then(res => res.data).then(developer => {
			dispatch({
				type: DEVELOPER_LOOKUP_SUCCESS,
				developer
			});
		}).catch(err => {
			const error = errorResponse(err);
			dispatch({
				type: DEVELOPER_LOOKUP_FAIL,
				error
			});
		});
	}
}