import axios from "axios";
import { API_ROOT } from "../constants";
import { errorResponse } from "../helpers";
import { addLog } from "./logger";

export const GET_ORDERS_START = "GET_ORDERS_START";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL";

export function getOrders(params = {}) {
	return async function (dispatch, getState) {

		const { session: { token } } = getState();

		dispatch({
			type: GET_ORDERS_START
		});

		try {
			const response = await axios.get(`${API_ROOT}/orders/mine`, {
				params,
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			dispatch({
				type: GET_ORDERS_SUCCESS,
				orders: response.data
			});
		} catch (error) {
			const errorMessage = errorResponse(error);
			console.log("error getting orders", errorMessage, error);
			dispatch(addLog({
				type: "error",
				text: "Couldn't get orders: " + errorMessage
			}));
			dispatch({
				type: GET_ORDERS_FAIL,
				error: errorMessage
			});
		}
	}
}
