import React, { useEffect, useState, useContext, createContext, useCallback } from "react";
import { ImgIcon } from "../assets/ImgIcon";
import { cross } from "../assets/icons";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useHistory } from "react-router-dom";

const ModalContext = createContext();

function Modal({ open, closeModal, content, closable, contentKey = "none", className }) {

	const [validDownTarget, setValidDownTarget] = useState(false);
	const [wasAlreadyOpen, setWasAlreadyOpen] = useState(open);

	useEffect(() => {
		let to = setTimeout(() => setWasAlreadyOpen(open), 500);
		return () => {
			clearTimeout(to);
		}
	}, [open]);

	useEffect(() => {
		function onKeyUp(e) {
			if (open && e.key === "Escape") closeModal();
		}
		if (open) document.addEventListener("keyup", onKeyUp);
		return () => {
			document.removeEventListener("keyup", onKeyUp);
		}
	}, [open, closeModal]);

	return <CSSTransition
		key="modal"
		in={open}
		timeout={500}
		classNames="modal"
	>
		<div
            className={`modal-wrapper modal-wrapper-${open ? "open modal-enter-done" : "closed modal-exit-done"}`}
			onMouseDown={e => {
				e.stopPropagation();
				setValidDownTarget(true);
			}}
			onMouseUp={e => {
				e.stopPropagation();
				if (validDownTarget && open) closeModal();
				setValidDownTarget(false);
			}}
		>
			<div
				className={`modal-inner ${className ? className : ''}`}
				onMouseDown={e => e.stopPropagation()}
				onMouseUp={e => {
					e.stopPropagation();
					setValidDownTarget(false);
				}}
			>
				{/* If the modal was already open, we want to render the content in a transition group
					so it can be switched elegantly.*/}
				{wasAlreadyOpen ?
					<SwitchTransition>
						<CSSTransition
							key={contentKey}
							timeout={500}
							classNames="modal-switch"
						>
							<div className="modal-inner-content">
							{closable && <ImgIcon
								bg={"#ffffff"}
								size={30}
								iconSize={"70%"}
								onClick={e => {
									closeModal();
								}}
								className="close-modal noSelect"
								src={cross}
							/>}
								{content}
							</div>
						</CSSTransition>
					</SwitchTransition>
					:
					<div className="modal-inner-content">
						{content}
					</div>
				}
			</div>
		</div>
	</CSSTransition>
}

export const ModalProvider = ({ children }) => {

	const history = useHistory();
	const [content, setContent] = useState("");
	const [contentKey, setContentKey] = useState(null);
	const [config, setConfig] = useState({
        closable: true
	});
	const [open, setOpen] = useState(false);

	const openModal = useCallback(() => {
		if (window.history && window.history.pushState) {
			window.history.pushState({}, "", window.location.href);
		}
		document.querySelector("body").style.overflow = "hidden";
		setOpen(true);
	}, [setOpen]);

	const closeModal = useCallback(() => {
		document.querySelector("body").style.overflow = "auto";
		setOpen(false);
	}, [setOpen]);

	const configModal = useCallback((newConfig = {}) => {
		setConfig({ ...newConfig });
	}, [setConfig]);

	const setModal = useCallback((newContent = null, contentKey = null, newConfig) => {
		setContent(typeof newContent === "function" ? newContent() : newContent);
        if (contentKey) setContentKey(contentKey);
        if (newConfig) setConfig({ ...newConfig });
	}, [setContent, setContentKey]);

	history.listen((location, action) => {
		if (action === "POP" && open) closeModal();
	});

	return <ModalContext.Provider value={{
		setModal,
		openModal,
		closeModal,
		configModal,
		toggleModal: () => open ? closeModal() : openModal(),
		modalOpen: open
	}}>
		{children}
		<Modal
			open={open}
			openModal={openModal}
			closeModal={closeModal}
			content={content}
			contentKey={contentKey}
			{...config}
		/>
	</ModalContext.Provider>

};

export const useModal = () => useContext(ModalContext);
