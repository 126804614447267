import React from "react";
import { plus, minus } from "../icons";

export function HeroImage({ src, children, overlay = null }) {
	return <div className="hero-image" style={{ backgroundImage: `url(${src})` }}>
		{overlay && <div className="overlay" style={{ backgroundColor: overlay }}></div>}
		{children}
	</div>
}

export function Button({
	children,
	loading,
	onClick,
	disabled,
	className,
	spinnerSize = 15,
	spinnerColor,
	fullWidth,
	...props
}) {

	return (
		<button
			className={[
				className,
				fullWidth ? "fullWidth" : null,
				loading ? "loading" : null,
				disabled ? "disabled" : null
			].filter(c => c).join(" ")}
			disabled={disabled}
			onClick={e => {
				if (onClick && !disabled && !loading)
					onClick(e);
			}}
			{...props}
		>
			{loading ? <Spinner size={spinnerSize} color={spinnerColor || "#ffffff"} /> : children}
		</button>
	)
}

export function ImgIcon({ style, className, size, src, onClick, cursor, iconSize, ...otherProps }) {
	return <span
		className={"imgIcon " + (className || "")}
		onClick={onClick}
		style={Object.assign({
			backgroundImage: `url("${src}")`,
			backgroundSize: iconSize || "contain",
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center",
			height: size || 50,
			width: size || 50,
			lineHeight: `${size || 50}px`,
			textAlign: "center",
			fontWeight: "bold",
			display: "inline-block",
			cursor: cursor ? cursor : (onClick ? "pointer" : "default"),
			fontSize: "0.8rem",
		}, style || {})}
		{...otherProps}
	/>
}

export function AssetImage({ style, className, size, src, onClick, cursor, iconSize, ...otherProps }) {
	return <img
		alt=""
		src={src ? require(`${src}`) : require('./icon/checkout.svg')}
		className={"imgIcon " + (className || "")}
		onClick={onClick}
		// onload="SVGInject(this)"
		style={{
			height: size || 50,
			width: size || 50,
			lineHeight: `${size || 50}px`,
			textAlign: "center",
			fontWeight: "bold",
			display: "inline-block",
			cursor: cursor ? cursor : (onClick ? "pointer" : "default"),
			fontSize: "0.8rem",
		}}
		{...otherProps}
	/>
}

export function Spinner({ size = 25, color = "#ffffff", style = {} }) {
	return (
		<span
			className="lds-dual-ring"
			style={{
				...style,
				width: size,
				height: size
			}}
		>
			<span className="inner" style={{
				width: size * 0.8,
				height: size * 0.8,
				borderWidth: size * 0.15,
				borderColor: `${color} transparent ${color} transparent`
			}} />
		</span>
	)
}

export function SpinnerWrapper({ children, style = {}, ...props }) {
	return (
		<div style={{
			height: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			...style
		}} {...props} >
			{children}
		</div>
	)
}

export function QuantitySelector({ value, onChange, min = 0, max = 30 }) {

	return <div className="quantity-selector">
		<ImgIcon
			className="align-center"
			style={{
				opacity: value === min ? "0.3" : "1",
			}}
			size={16}
			src={minus}
			onClick={() => {
				let newValue = value - 1;
				if (newValue < min) return;
				onChange(newValue);
			}}
		/>
		<span
			className="align-center count" style={{
				textAlign: "center",
				cursor: "default"
			}}>
			<b>{value}</b> in basket
		</span>
		<ImgIcon
			className="align-center"
			style={{
				opacity: value >= max ? "0.3" : "1",
			}}
			size={16}
			src={plus}
			onClick={() => {
				let newValue = value + 1;
				if (newValue > max) return;
				onChange(newValue)
			}}
		/>
	</div>
}
