import { initialState } from ".";
import { GET_SETTINGS_START, GET_SETTINGS_SUCCESS, GET_SETTINGS_FAIL } from "../actions/settings";

export default function (state = initialState.settings, action) {
    switch (action.type) {

        case GET_SETTINGS_START:
            return {
                ...state
            };
        case GET_SETTINGS_SUCCESS:
            return {
                ...state,
                ...action.settings,
                loading: false
            };
        case GET_SETTINGS_FAIL:
            return {
                ...state,
                loading: false
            };

        default:
            return state

    }    
}