import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AssetImage } from "../../assets";
import { getItemsCount } from "../../views/Property/CartBar";

export default function CartIcon() {
	const cartItems = useSelector(state => state.cart.items);
	const itemCount = getItemsCount(cartItems);
	
	return <Link to="/checkout" className="bottom-bar-link">
		<AssetImage cursor="pointer" src={'./icon/checkout.svg'}></AssetImage>
		{Boolean(itemCount) && <div className="cart-item-count">{itemCount}</div>}
	</Link>
}
