import axios from "axios";
import { API_ROOT } from "../constants";
import { errorResponse } from "../helpers";

export const COUPON_LOOKUP_START = "COUPON_LOOKUP_START";
export const COUPON_LOOKUP_SUCCESS = "COUPON_LOOKUP_SUCCESS";
export const COUPON_LOOKUP_FAIL = "COUPON_LOOKUP_FAIL";

export const COUPON_REMOVE_START = "COUPON_REMOVE_START";
export const COUPON_REMOVE_SUCCESS = "COUPON_REMOVE_SUCCESS";
export const COUPON_REMOVE_FAIL = "COUPON_REMOVE_FAIL";

export function couponLookup(code, cb) {
	return function (dispatch) {

		dispatch({
			type: COUPON_LOOKUP_START,
			code
		});

		axios.get(`${API_ROOT}/coupons/bycode/${code}`).then(res => res.data)
			.then(coupon => {
				dispatch({
					type: COUPON_LOOKUP_SUCCESS,
					coupon
				});

				if (cb) cb(null);
			}).catch(err => {
				const error = errorResponse(err);
				dispatch({
					type: COUPON_LOOKUP_FAIL,
					error
				});

				if (cb) cb(error);
			});
	}
}

export function couponRemove(cb) {
	return function (dispatch, getState) {

		dispatch({
			type: COUPON_REMOVE_START
		});

		const stateCart = getState().cart;

		if (stateCart.coupon && stateCart.coupon.code && stateCart.coupon.price) {
			const total = stateCart.total + stateCart.coupon.price;

			dispatch({
				type: COUPON_REMOVE_SUCCESS,
				total
			});

			if (cb) cb();
		}
	}
}
