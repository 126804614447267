import { initialState } from ".";
import {
	CART_UPDATE_SUCCESS,
	CART_CLEAR,
} from "../actions/cart";
import {
	COUPON_LOOKUP_FAIL,
	COUPON_LOOKUP_START,
	COUPON_LOOKUP_SUCCESS,
	COUPON_REMOVE_START,
	COUPON_REMOVE_SUCCESS
} from "../actions/coupon";

export default function (state = initialState.cart, action) {
	switch (action.type) {

		case CART_UPDATE_SUCCESS:
			return {
				...state,
				...action.changes
			}

		case COUPON_LOOKUP_FAIL:
			return {
				...state,
				loading: false
			}

		case COUPON_LOOKUP_START:
		case COUPON_REMOVE_START:
			return {
				...state,
				loading: true
			}

		case COUPON_LOOKUP_SUCCESS:
			return {
				...state,
				loading: false,
				coupon: {
					code: action.coupon.code,
					price: action.coupon.price
				},
				total: state.total - action.coupon.price
			}

		case COUPON_REMOVE_SUCCESS:
			return {
				...state,
				loading: false,
				coupon: {},
				total: action.total
			}

		case CART_CLEAR:
			return {
				...state,
				coupon: {},
				deliveryNote: "",
				items: [],
				property: null,
				total: 0
			}

		default:
			return state
	}
}
