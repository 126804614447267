import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACCOUNT_CONFIRMATION, EMAIL_LOGIN, InputField } from ".";
import { signup } from "../../actions/session";
import { Button } from "../../assets";
import { EMAIL_REGEX, PASSWORD_REGEX, PHONE_REGEX } from "../../constants";

function registerFormValidation({ firstName, lastName, email, telephone, password, confirm }) {
	return {
		firstName: firstName ? false : "First name is required.",
		lastName: lastName ? false : "Last name is required.",
		email: email
			? EMAIL_REGEX.test(email) ? false : "Please provide valid email address."
			: "Email is required.",
		telephone: telephone
			? PHONE_REGEX.test(telephone) ? false : "Incorrect telephone number."
			: false,
		password: password
			? PASSWORD_REGEX.test(password) ? false : "Password must be between 8 and 40 characters and contain letters and numbers."
			: "Password is required.",
		confirm: Boolean(password === confirm) ? false : "Passwords have to match."
	}
};

export default function Signup({
	values,
	errors,
	onInputChange,
	onViewChange,
	additionalUserData,
	onError
}) {

	const dispatch = useDispatch();
	const { loading } = useSelector(state => ({
		loading: state.session.loading
	}));

	const [attemptMade, setAttemptMade] = useState(false);

	useEffect(() => {
		// Checking for input errors.
		const newErrors = {};
		for (const [key, value] of Object.entries(registerFormValidation(values))) {
			if (errors && errors[key] !== value) newErrors[key] = value;
		}
		if (Object.keys(newErrors).length) onError(newErrors);
	}, [errors, onError, values]);

	// Get error messages.
	const errorMessages = Object.keys(errors).map(key => errors[key]).filter(Boolean);

	return <div>
		<form onSubmit={e => {
			e.preventDefault();
			// If this submitting fot the first time set attemptMade to true.
			if (!attemptMade) setAttemptMade(true);
			// If no validation errors, submit.
			if (errorMessages.length) return null;
			dispatch(signup({
				email: values.email.toLowerCase(),
				username: values.email.toLowerCase(),
				telephone: values.telephone,
				firstName: values.firstName,
				lastName: values.lastName,
				password: values.password,
				...additionalUserData
			}, () => {
				onViewChange(ACCOUNT_CONFIRMATION);
			}));
		}}>
			<h4>Sign up</h4>
			<InputField
				attemptMade={attemptMade}
				name="email"
				placeholder="Email"
				values={values}
				errors={errors}
				onInputChange={onInputChange}
			/>
			<InputField
				attemptMade={attemptMade}
				name="firstName"
				placeholder="First name"
				values={values}
				errors={errors}
				onInputChange={onInputChange}
			/>
			<InputField
				attemptMade={attemptMade}
				name="lastName"
				placeholder="Last name"
				values={values}
				errors={errors}
				onInputChange={onInputChange}
			/>
			<InputField
				attemptMade={attemptMade}
				name="telephone"
				placeholder="Telephone"
				values={values}
				errors={errors}
				onInputChange={onInputChange}
			/>
			<InputField
				attemptMade={attemptMade}
				name="password"
				type="password"
				placeholder="Password"
				values={values}
				errors={errors}
				onInputChange={onInputChange}
			/>
			<InputField
				attemptMade={attemptMade}
				name="confirm"
				type="password"
				placeholder="Confirm password"
				values={values}
				errors={errors}
				onInputChange={onInputChange}
			/>
			<div className="input-field login-button">
				<Button
					loading={loading}
					className=" default-font medium"
				>Sign up</Button>
			</div>
		</form>
		<div className="info">
			Already got an account?
		</div>
		<div className="input-field login-button">
			<Button
				className=" default-font medium"
				onClick={e => onViewChange(EMAIL_LOGIN)}
			>Log in instead</Button>
		</div>
	</div >
}
