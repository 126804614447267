import React from "react";
import { EMAIL_LOGIN, RESET_PASSWORD_REQUEST } from ".";
import { Button } from "../../assets";

export default function AccountExistsConfirmed({ onViewChange }) {
	return <div>
		<h4>You've Already Got An Account!</h4>
		<div className="info">
			Click the button below to log in.
		</div>
		<div className="input-field login-button">
			<Button className="default-font medium" onClick={e => onViewChange(EMAIL_LOGIN)}>Log in</Button>
		</div>
		<div className="info info-warning">
			<b>ATTENTION:</b> If you never set a password, your account was most likely created for you when you visited a venue in person.
			Click the button below to set a password.
		</div>
		<div className="input-field login-button">
			<Button className=" default-font medium" onClick={e => onViewChange(RESET_PASSWORD_REQUEST)}>Set password</Button>
		</div>
	</div>
}
