import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux"
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { MAX_LOG_AGE } from "../constants";
import moment from "moment";
import { removeLog, setLogs } from "../actions/logger";
import { useRef } from "react";

export default function Logger({ ...props }) {

	const dispatch = useDispatch();
	const { logs } = useSelector(state => state.logger);

	useEffect(() => {

		function clearOldLogs() {
			let newLogs = logs.filter(log => log.timestamp ?
				moment().diff(moment(log.timestamp), "seconds") < MAX_LOG_AGE : true
			);
			if (newLogs.map(log => log.id).join("_") !== logs.map(log => log.id).join("_"))
				dispatch(setLogs(newLogs));
		}

		clearOldLogs();

		let interval = setInterval(clearOldLogs, 2000);

		return () => {
			clearInterval(interval);
		}

	}, [logs, dispatch])

	return (
		<TransitionGroup className="logger-list" {...props}>
			{logs.map(log => {
				return (
					<CSSTransition
						key={log.id}
						timeout={300}
						classNames="log"
					>
						<LogItem log={log} />
					</CSSTransition>
				)
			})}
		</TransitionGroup>
	)
}

function LogItem({ log }) {

	const dispatch = useDispatch();
	const elem = useRef(null);

	useEffect(() => {
		elem.current.style.height = elem.current.offsetHeight + "px";
	}, []);

	return <div
		ref={elem}
		className={`log text-center ${log.type}`}
		onClick={() => dispatch(removeLog(log.id))}
	>
		{log.text || log.message}
	</div>
}