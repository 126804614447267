import React from "react";

export function ImgIcon({ style, className, size, iconSize, src, onClick, cursor, cover, bg, ...otherProps }) {
	return <span
		className={"imgIcon " + (className || "")}
		onClick={onClick}
		style={{
			backgroundImage: `url("${src}")`,
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center",
			backgroundColor: bg ? bg : undefined,
			height: size || 50,
			width: size || 50,
			lineHeight: `${size || 50}px`,
			textAlign: "center",
			fontWeight: "bold",
			display: "inline-block",
			cursor: cursor ? cursor : (onClick ? "pointer" : "default"),
			fontSize: "0.8rem",
			...(iconSize ? { backgroundSize: iconSize } : { backgroundSize: cover ? "cover" : "contain" }),
			...(style || {})
		}}
		{...otherProps}
	/>
}
