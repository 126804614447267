import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Helmet } from "react-helmet";
import { Button } from "../../assets";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
	updateCart,
	updateInCart,
} from "../../actions/cart";
import CartTools from "../../components/CartTools";
import { formatDescriptionData } from "../../helpers";
import { PAYMENT_FAILED } from "../../constants";
import { addLog } from "../../actions/logger";
import QuantityPicker from "../../assets/QuantityPicker";

export default function Checkout() {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const timer = useRef(null);
	const {
		deliveryNote,
		items,
		total
	} = useSelector(state => state.cart, shallowEqual);
	const [note, setNote] = useState(deliveryNote);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);

		if (queryParams.has("payment") && queryParams.get("payment") === PAYMENT_FAILED) {
			dispatch(addLog({
				type: "error",
				text: "Payment failed"
			}));
			queryParams.delete("payment");
			history.replace({
				search: queryParams.toString(),
			})
		}
	}, [dispatch, history, location.search])

	useEffect(() => {
		timer.current = setTimeout(() => {
			if (deliveryNote !== note) {
				dispatch(updateCart({
					deliveryNote: note
				}));
			}
		}, 300);

		return () => clearTimeout(timer.current);
	}, [dispatch, deliveryNote, note])

	return <div className="checkout" >
		<Helmet title="Checkout" />
		<section>
			<p className="title">Your Selection</p>
		</section>

		{items.length
			?
			<>
				{items && items.length > 5 && <section className="search">
					<CartTools total={total} />
				</section>}
				<section className="product-list">
					{items.map(item =>
						<CheckoutItem
							key={item.product.id + "_" + item.variant}
							item={item}
						/>)}
				</section>
				<section className="note-input">
					<textarea
						className="default-font medium"
						type="text"
						placeholder="Add a delivery note to your order"
						value={note}
						onChange={e => setNote(e.target.value)}
					/>
				</section>
				<section className="search">
					<CartTools total={total} />
				</section>
			</>
			:
			<div className="no-item">
				<p>Your cart is empty</p>
				<Button
					style={{
						margin: "0 auto"
					}}
					className="default-font medium"
				>
					<Link to="/" className="anchor">Back to <b>Select</b></Link>
				</Button>
			</div>}

	</div>
};

function CheckoutItem({ item }) {

	const dispatch = useDispatch();

	const product = item.product;
	const imageSrc = useMemo(() => {
		const result = product.images && product.images[0]
			? (product.images[0].formats &&
				product.images[0].formats.thumbnail) || { url: product.images[0].url }
			: {};

		return result.url || "";
	}, [product]);

	const variantName = product.variants.length > 1 ? item.variant : null;

	const formatVariantTitle = title => title
		.replace(/:/g, ": ")
		.replace(/\s\|\s/g, "\n");

	return <div className="checkout-item">
		<div className="checkout-item-image">
			<img src={imageSrc} alt="product" />
		</div>
		<div className="checkout-item-desc">
			<p className="product-title">{product.title}</p>
			{variantName && <p className="product-subtitle whitespace-pre">{formatVariantTitle(variantName)}</p>}
			{Boolean(item.installation) && <p className="product-subtitle">
				With installation
			</p>}
			<div className="subtitle" dangerouslySetInnerHTML={{ __html: formatDescriptionData(product.bodyHtml).split("</p>")[0] + "</p>" }} />
		</div>
		<div className="checkout-item-update">
			<QuantityPicker
				max={item.max}
				value={item.quantity}
				onChange={quantity => {
					if (
						quantity !== 0 ||
						window.confirm("Are yu sure you want to remove this item?")
					) dispatch(updateInCart(
						item.product,
						item.variant,
						quantity
					))
				}}
			/>
		</div>
	</div>
};
