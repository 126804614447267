import React from "react";
import { AssetImage } from ".";

export default function QuantityPicker({
	value = 0,
	onChange,
	min = 0,
	max = 100
}) {

	const canAdd = value < max;
	const canSubtract = value > min;

	return <div className="quantity-picker flexy">
		<button
			disabled={!canSubtract}
			onClick={!canSubtract ? null : e => onChange(value - 1)}
		>
			<AssetImage
				cursor="inherit"
				src={"./icon/subtract.svg"}
				size={20}
			/>
		</button>
		<div>{value}</div>
		<button
			disabled={!canAdd}
			onClick={!canAdd ? null : e => onChange(value + 1)}
		>
			<AssetImage
				cursor="inherit"
				src={"./icon/add.svg"}
				size={20}
			/>
		</button>
	</div>
}
