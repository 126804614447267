import React, { useState, useRef, useEffect } from "react";

export default function VerticalExpander({ open = false, speed = 150, children }) {

	const innerContainer = useRef(null);

	const [height, setHeight] = useState(0);

	useEffect(() => {
		if (innerContainer.current) setHeight(innerContainer.current.offsetHeight);
	}, [children]);

	return <div
            style={{
                overflow: `${open ? "initial" : "hidden"}`,
                height: `${open ? height : 0}px`,
                transform: `${open ? "scaleY(1) translateY(0px)" : "scaleY(0.8) translateY(-5px)"}`,
                transformOrigin: `top center`,
                opacity: `${open ? 1 : 0}`,
                transition: `all ${speed / 1000}s cubic-bezier(0,.64,.74,1.14)`,
                pointerEvents: `${open ? "all" : "none"}`    
            }}
	>
		<div ref={innerContainer}>{children}</div>
	</div>

}