import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACCOUNT_CONFIRMATION, EMAIL_SIGNUP, InputField, RESET_PASSWORD_REQUEST } from ".";
import { login, sendEmailConfirmation } from "../../actions/session";
import { Button } from "../../assets";
import { PASSWORD_REGEX, EMAIL_REGEX } from "../../constants";

function loginFormValidation({ email, password }) {
	return {
		email: email
			? EMAIL_REGEX.test(email) ? false : "Please provide valid email address."
			: "Email is required.",
		password: password
			? PASSWORD_REGEX.test(password) ? false : "Password must be between 8 and 40 characters and contain letters and numbers."
			: "Password is required."
	}
};

export default function Login({
	values,
	errors,
	onInputChange,
	onChange,
	onViewChange,
	onLoginSuccess,
	onError
}) {

	const dispatch = useDispatch();
	const {
		query: { emailConfirmed },
		loginError,
		loading
	} = useSelector(state => state.session);

	const [showConfirmedMessage, setShowConfirmedMessage] = useState(false);
	const [attemptMade, setAttemptMade] = useState(false);

	const email = values.email;

	useEffect(() => {
		// Checking for input errors.
		const newErrors = {};
		for (const [key, value] of Object.entries(loginFormValidation(values))) {
			if (errors && errors[key] !== value) newErrors[key] = value;
		}
		if (Object.keys(newErrors).length) onError(newErrors);
	}, [errors, onError, values]);

	// Send unconfirmed users to an account confirmation page.
	useEffect(() => {
		if (
			loginError &&
			loginError === "Your account email is not confirmed"
		) {
			// Go to the account confirmation view.
			onViewChange(ACCOUNT_CONFIRMATION);
			// Send a confirmation email.
			if (email) dispatch(sendEmailConfirmation(email));
		}
	}, [dispatch, email, loginError, onViewChange]);

	useEffect(() => {
		if (emailConfirmed === "true") setShowConfirmedMessage(true);
	}, [emailConfirmed]);

	// Get error messages.
	const errorMessages = Object.keys(errors).map(key => errors[key]).filter(Boolean);

	return <div>
		<form onSubmit={e => {
			e.preventDefault();
			// If this submitting fot the first time set attemptMade to true.
			if (!attemptMade) setAttemptMade(true);
			// If no validation errors, submit.
			if (errorMessages.length) return null;
			dispatch(login({
				email: values.email,
				password: values.password
			}, onLoginSuccess));
		}}>
			<h4>Log in</h4>

			{/* Render message to users who have just confirmed their account */}
			<div key="info-msg" className="info info-success" style={{
				display: showConfirmedMessage ? "block" : "none"
			}}>
				Your account has been confirmed! Log in to continue.
			</div>

			<InputField
				attemptMade={attemptMade}
				key="login_email"
				name="email"
				placeholder="Email"
				values={values}
				errors={errors}
				onInputChange={onInputChange}
			/>
			<InputField
				attemptMade={attemptMade}
				key="login_password"
				name="password"
				placeholder="Password"
				type="password"
				values={values}
				errors={errors}
				onInputChange={onInputChange}
			/>
			<div className="input-field login-button">
				<Button
					loading={loading}
					className="default-font medium"
				>Log in</Button>
			</div>
		</form>
		<div className="info">
			Forgotten your password? <b className="link pointer" onClick={e => {
				onChange({ password: "" });
				onViewChange(RESET_PASSWORD_REQUEST);
			}}>Click here</b>.
			<br /><br />
			No account yet?
		</div>
		<div className="input-field login-button">
			<Button
				className=" default-font medium"
				onClick={e => {
					onChange({ password: "" });
					onViewChange(EMAIL_SIGNUP);
				}}
			>Create account</Button>
		</div>
	</div>

}
