export const MAX_LOG_AGE = 5;
export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const MEDIA_ROOT = process.env.REACT_APP_MEDIA_ROOT || "";
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const SHOPIFY_ROOT = "https://avandeconnect.com";
// export const SHOPIFY_ASSET_ROOT = "//cdn.shopify.com/s/files/1/0023/4317/4199/t/20/assets";
export const SHOPIFY_ASSET_ROOT = "//cdn.shopify.com/s/files/1/0023/4317/4199/t/24/assets";
export const POST_CODE_REGEX = /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/i;
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = /^[\d- ()+]{7,20}$/;
export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z0-9@$!%*#?&]{8,40}$/;

// User email statuses.
export const NO_USER = 1; // Non-existant users.
export const CONFIRMED_USER = 2; // Confirmed users.
export const UNCONFIRMED_USER = 3; // Unconfirmed users.
export const COOKIE_NAME = "avande_connect_token";

export const DEFAULT_BRAND = {
	color1: "#29348f",
	color2: "#ffffff"
}

export const PAYMENT_SUCCEEDED = "success";
export const PAYMENT_FAILED = "fail";

// Order statuses.
export const ORDER_PENDING = "pending";
export const ORDER_CANCELLED = "cancelled";
export const ORDER_ORDERED = "ordered";
export const ORDER_DISPATCHED = "dispatched";
export const ORDER_COMPLETED = "completed";
