import React from "react";
import { AssetImage } from "../../assets";

export default function Footer(props) {
	return <section className="footer" >
		{/* <section className='top-footer'>
            <p className="title">Packages</p>
            <p>CCTV</p>
            <p>Comfort</p>
            <p>Home Cinema</p>
            <p>Lighting Control</p>
            <p>Multiroom Audio</p>
            <p>Network &amp; Wifi</p>
            <p>Security</p>
            <p>Smart Home</p>
        </section> */}
		<section className='top-footer'>
			<p className="title">Help</p>
			<a href="https://avandeconnect.com/pages/approved-partners">Approved Partners</a>
			<a href="https://avandeconnect.com/pages/installation">Installation</a>
			<a href="https://avandeconnect.com/pages/contact-1">Contact</a>
		</section>
		<section className='top-footer'>
			<p className="title">Pages</p>
			<a href="https://avandeconnect.com/pages/services">Our Services</a>
			<a href="https://avandeconnect.com/blogs/articles">Articles</a>
			<a href="https://avandeconnect.com/blogs/guides">Guides</a>
		</section>
		<section className='top-footer'>
			<p className="title">Social Media</p>
			<div className="social-media">
				<a href="https://twitter.com/avandeconnect">
					<AssetImage
						className="social-media-icon"
						size={35}
						src={'./icon/twitter.svg'}
						cursor="pointer"
					/>
				</a>
				<a href="https://www.instagram.com/avandeconnect">
					<AssetImage
						className="social-media-icon"
						size={35}
						src={'./icon/insta.svg'}
						cursor="pointer"
					/>
				</a>
				<a href="https://www.linkedin.com/company/avandeinfo/">
					<AssetImage
						className="social-media-icon"
						size={35}
						src={'./icon/linkedin.svg'}
						cursor="pointer"
					/>
				</a>
			</div>
		</section>
		<section className="bottom-footer">
			<ul className="inline-list">
				<li className="nav-item">
					<a href="https://avandeconnect.com/search">Search</a>
				</li>
				<li className="divider">/</li>
				<li className="nav-item">
					<a href="https://avandeconnect.com/policies/privacy-policy">Privacy Policy</a>
				</li>
				<li className="divider">/</li>
				<li className="nav-item">
					<a href="https://avandeconnect.com/policies/refund-policy">Refund Policy</a>
				</li>
				<li className="divider">/</li>
				<li className="nav-item">
					<a href="https://avandeconnect.com/policies/terms-of-service">Terms of Service</a>
				</li>
			</ul>
			{/* <div className="payment-div">
                <div className='payment-method'></div>
                <div className='payment-method'></div>
                <div className='payment-method'></div>
                <div className='payment-method'></div>
                <div className='payment-method'></div>
                <div className='payment-method'></div>
                <div className='payment-method'></div>
                <div className='payment-method'></div>
                <div className='payment-method'></div>
                <div className='payment-method'></div>
            </div> */}
			<p className="title">@ 2021 Avande Connect. All prices displayed in GBP. Checkout in GBP</p>
		</section>
	</section>
};
