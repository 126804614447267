import { COOKIE_NAME } from "../constants";
import { getCookies } from "../helpers";
import { loadState } from "../helpers/hooks/localStorage"

const cookies = getCookies();
const persistedState = loadState("cart");

const query = new URLSearchParams(window.location.search);

export default {
	session: {
		query: {
			resetPassword: query.get("resetPassword") || "false",
			code: query.get("code") || "",
			email: query.get("email") || "",
			emailConfirmed: query.get("emailConfirmed") || "false"
		},
		ready: !Boolean(cookies[COOKIE_NAME]),
		loading: false,
		updating: false,
		updateError: null,
		loginError: null,
		token: cookies[COOKIE_NAME] || null,
		user: null,
		needsConfirmation: false
	},
	logger: {
		logs: []
	},
	settings: {
		loading: true
	},
	property: {
		loading: false,
		lookupError: null
	},
	developer: {
		loading: false,
	},
	development: {
		loading: false
	},
	cart: {
		coupon: persistedState ? persistedState.coupon : {},
		deliveryNote: "",
		items: persistedState ? persistedState.items : [],
		loading: false,
		property: persistedState ? persistedState.property : null,
		total: persistedState ? persistedState.total : 0,
	},
	orders: {
		loading: false,
		items: []
	}
}
