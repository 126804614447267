import React from "react";

export default function Dropdown({ value, options, onChange, itemKey = "title" }) {

	return <select value={value ? value[itemKey] : null} onChange={e => {
		e.stopPropagation();
		const match = options.find(option => option[itemKey] === e.target.value);
		onChange(match || null);
	}}>
		{options.map(option => {
			return <option value={option[itemKey]} key={option[itemKey]}>
				{option[itemKey]}
			</option>
		})}
	</select>
}
