import { initialState } from ".";
import { GET_ORDERS_FAIL, GET_ORDERS_START, GET_ORDERS_SUCCESS } from "../actions/orders";

export default function (state = initialState.orders, action) {
	switch (action.type) {

		case GET_ORDERS_START:
			return {
				...state,
				loading: true
			};

		case GET_ORDERS_SUCCESS:
			return {
				...state,
				items: action.orders,
				loading: false
			};

		case GET_ORDERS_FAIL:
			return {
				...state,
				loading: false
			}

		default:
			return state
	}
}
