import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EMAIL_LOGIN, InputField } from ".";
import { requestPasswordReset } from "../../actions/session";
import { Button } from "../../assets";

export default function ResetPasswordRequest({
	values,
	onInputChange,
	errors,
	onViewChange
}) {

	const dispatch = useDispatch();

	const { loading } = useSelector(state => state.session);

	const [requested, setRequested] = useState(false);

	return <div>
		<form onSubmit={e => {
			e.preventDefault();
			dispatch(requestPasswordReset({
				email: values.email
			}, () => {
				setRequested(true);
			}));
		}}>
			<h4>Reset Your Password</h4>
			<div className="info">
				Enter your current email address and we'll send you an email that will allow you to reset your password.
			</div>
			<InputField
				name="email"
				placeholder="Email"
				values={values}
				errors={errors}
				onInputChange={onInputChange}
			/>
			{!requested ?
				(
					<div className="input-field login-button">
						<Button
							className="default-font medium"
							loading={loading}
						>Request reset</Button>
					</div>
				) : (
					<div className="info info-success">
						Request sent successfully! Check your emails.
					</div>
				)
			}
		</form>
		<div className="info">
			Click the button below if you want to return to log in.
		</div>
		<div className="input-field login-button">
			<Button
				className=" default-font medium"
				onClick={e => onViewChange(EMAIL_LOGIN)}
			>Log in</Button>
		</div>
	</div>
}
