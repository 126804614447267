import axios from "axios";
import { API_ROOT } from "../constants";
import { errorResponse } from "../helpers";

export const GET_SETTINGS_START = "GET_SETTINGS_START";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAIL = "GET_SETTINGS_FAIL";

export function getSettings() {
	return function (dispatch) {
		
		dispatch({
			type: GET_SETTINGS_START
		});

		axios.get(`${API_ROOT}/public-settings`).then(res => res.data).then(settings => {
			dispatch({
				type: GET_SETTINGS_SUCCESS,
				settings
			});
		}).catch(err => {
			const error = errorResponse(err);
			dispatch({
				type: GET_SETTINGS_FAIL,
				error
			});
		});
	}
}