import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Helmet } from "react-helmet";
import { Button, Spinner } from "../../assets";
import {
	ORDER_CANCELLED,
	ORDER_COMPLETED,
	ORDER_DISPATCHED,
	ORDER_ORDERED,
	ORDER_PENDING,
	PASSWORD_REGEX,
	PAYMENT_SUCCEEDED,
	PHONE_REGEX
} from "../../constants";
import { formatDescriptionData } from "../../helpers";
import markdownIt from "markdown-it";
import moment from "moment";
import { Redirect, useHistory, useLocation, withRouter } from "react-router-dom";
import { clearCart } from "../../actions/cart";
import { updateMe } from "../../actions/session";
import { addLog } from "../../actions/logger";
import { getOrders } from "../../actions/orders";

// Initialize markdownIt.
const md = new markdownIt();
// Cache the data for product to prevent unnecessary calls.
const internalDescriptionCache = {}

function getProductDescriptionData(product) {
	if (internalDescriptionCache[product.id]) {
		return internalDescriptionCache[product.id];
	} else {
		const desc = md.render(product.bodyHtml || "");
		internalDescriptionCache[product.id] = desc;
		return desc;
	}
}

function userInfoValidation(target) {
	const { name, value } = target;
	switch (name) {
		case "firstName":
			return value ? false : "First name is required.";
		case "lastName":
			return value ? false : "Last name is required";
		case "telephone":
			return PHONE_REGEX.test(value) ? false : "Incorrect telephone number."
		default:
			return false
	}
};

function passwordValidation({ password, confirm }) {
	return {
		password: password
			? PASSWORD_REGEX.test(password) ? false : "Password must be between 8 and 40 characters and contain letters and numbers."
			: "Password is required",
		confirm: Boolean(password === confirm) ? false : "Passwords have to match."
	}
};

function ProfileView(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const { orders, ordersLoading, token, user } = useSelector(state => ({
		orders: state.orders.items,
		ordersLoading: state.orders.loading,
		token: state.session.token,
		user: state.session.user
	}), shallowEqual);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);

		if (queryParams.has("payment") && queryParams.get("payment") === PAYMENT_SUCCEEDED) {
			dispatch(clearCart());
			dispatch(addLog({
				type: "success",
				text: "Payment successful"
			}));
			queryParams.delete("payment");
			history.replace({
				search: queryParams.toString(),
			});
		}
	}, [dispatch, history, location.search]);

	useEffect(() => {
		if (token) dispatch(getOrders({ _sort: "id:DESC" }));
	}, [dispatch, token]);

	if (!user)
		return <Redirect to={"/login"} />;

	return <div className="profile" >
		<Helmet title="Account" />
		<section className="user-info">
			<p className="info-title">Account</p>
			<UserInfoSection />
			<PasswordSection />
		</section>
		<section className="user-order">
			<p className="info-title">Orders</p>
			{ordersLoading && <Spinner />}
			{Boolean(!ordersLoading && orders.length) &&
				orders.map(order => <OrderProduct key={order.id} order={order} />)
			}
			{!ordersLoading && !orders.length && <span>
				No orders found..
			</span>}
		</section>
	</div>
};

function UserInfoSection() {
	const dispatch = useDispatch();
	const { user, updating } = useSelector(state => ({
		user: state.session.user,
		updating: state.session.updating
	}), shallowEqual);
	const [editMode, setEditMode] = useState(false);
	const [changes, setChanges] = useState({});
	const [attemptMade, setAttemptMade] = useState(false);
	const [errors, setErrors] = useState({});

	const values = {
		...user,
		...changes
	};

	useEffect(() => {
		// Checking for input errors.
		let errors = {};
		Object.keys(changes).forEach(key => {
			let inputError = userInfoValidation({ name: key, value: changes[key] });
			if (inputError) errors[key] = inputError;
		});
		setErrors(errors);
	}, [changes]);

	// Only show input errors if submit attempt was made.
	const fieldErrors = attemptMade ? errors : {};

	// Get error messages.
	const errorMessages = Object.keys(errors).map(key => errors[key]).filter(Boolean);

	const handleUserInfoUpdate = () => {
		// If this submitting fot the first time set attemptMade to true.
		if (!attemptMade) setAttemptMade(true);
		// If no validation errors, submit.
		if (!errorMessages.length) {
			dispatch(updateMe(changes, () => {
				// Success, reset component's state
				setAttemptMade(false);
				setEditMode(false);
				// Clear all errors and changes
				setChanges({});
				setErrors({});
				// Show success message
				dispatch(addLog({
					type: "success",
					text: "Update successful"
				}))
			}))
		}
	}

	if (!user) return null;

	return <>
		<div className="subtitle user-profile">
			{editMode ?
				<>
					<div className="fullName">
						<input
							name="firstName"
							type="text"
							className="default-font medium"
							placeholder="First Name"
							value={values.firstName}
							onChange={e => setChanges({
								...changes,
								[e.target.name]: e.target.value
							})}
						/>

						<input
							name="lastName"
							type="text"
							className="default-font medium"
							placeholder="Last Name"
							value={values.lastName}
							onChange={e => setChanges({
								...changes,
								[e.target.name]: e.target.value
							})}
						/>
						{Boolean(fieldErrors["firstName"]) && <div className="error">
							{fieldErrors["firstName"]}
						</div>}
						{Boolean(fieldErrors["lastName"]) && <div className="error">
							{fieldErrors["lastName"]}
						</div>}
					</div>
					<input
						name="telephone"
						type="text"
						className="default-font medium"
						placeholder="Telephone"
						value={values.telephone || ""}
						onChange={e => setChanges({
							...changes,
							[e.target.name]: e.target.value
						})}
					/>
					{Boolean(fieldErrors["telephone"]) && <div className="error">
						{fieldErrors["telephone"]}
					</div>}
				</>
				:
				<>
					<p>{(user.firstName || user.lastName) ? `${user.firstName || ''} ${user.lastName || ''}` : 'Full Name'}</p>
					{<p>{user.telephone ? user.telephone : 'Telephone'}</p>}
				</>
			}
		</div>
		{!editMode
			?
			<Button
				className="default-font medium edit-button"
				onClick={() => setEditMode(true)}
			>
				Edit
			</Button>
			:
			<div className="update-button-container">
				<Button
					className="default-font medium cancel"
					onClick={() => {
						setChanges({});
						setEditMode(false);
					}}
				>
					Cancel
				</Button>
				<Button
					disabled={!Object.keys(changes).length || updating}
					className="default-font medium update"
					onClick={handleUserInfoUpdate}
				>
					{updating
						? "Saving..."
						: "Save"
					}
				</Button>
			</div>}
	</>;
}

function PasswordSection() {
	const dispatch = useDispatch();

	const { user, updating } = useSelector(state => ({
		user: state.session.user,
		updating: state.session.updating
	}), shallowEqual);
	const [passwordChanges, setPasswordChanges] = useState({ password: "", confirm: "" });
	const [attemptMade, setAttemptMade] = useState(false);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		setErrors(passwordValidation(passwordChanges));
	}, [passwordChanges]);

	// Only show input errors if submit attempt was made
	const fieldErrors = attemptMade ? errors : {};

	// Get error messages
	const errorMessages = Object.keys(errors).map(key => errors[key]).filter(Boolean);

	const handlePasswordUpdate = e => {
		e.preventDefault();
		// If this submitting fot the first time set attemptMade to true.
		if (!attemptMade) setAttemptMade(true);
		// If no validation errors, submit.
		if (!errorMessages.length) {
			dispatch(updateMe({ password: passwordChanges.password }, () => {
				// Success, reset component's state
				setAttemptMade(false);
				// Clear all errors
				setErrors({});
				// Reset values
				setPasswordChanges({ password: "", confirm: "" });
				// And show success message.
				dispatch(addLog({
					type: "success",
					text: "Update successful"
				}));
			}))
		}
	}

	return <div>
		<form onSubmit={handlePasswordUpdate}>
			<div className="subtitle user-password">
				{
					user && <>
						<input
							name="password"
							type="password"
							className="default-font medium"
							placeholder="new password"
							value={passwordChanges.password}
							onChange={e => setPasswordChanges({
								...passwordChanges,
								[e.target.name]: e.target.value
							})}
						/>
						{Boolean(fieldErrors["password"]) && <div className="error">
							{fieldErrors["password"]}
						</div>}
						<input
							name="confirm"
							type="password"
							className="default-font medium"
							placeholder="new password"
							value={passwordChanges.confirm}
							onChange={e => setPasswordChanges({
								...passwordChanges,
								[e.target.name]: e.target.value
							})}
						/>
						{Boolean(fieldErrors["confirm"]) && <div className="error">
							{fieldErrors["confirm"]}
						</div>}
					</>
				}
			</div>
			{(passwordChanges.password || passwordChanges.confirm) && <Button
				className="default-font medium edit-button"
				disabled={updating}
				type="submit"
			>
				{updating
					? "Saving..."
					: "Save"
				}
			</Button>}
		</form>
	</div>;

}

const OrderProduct = ({ order }) => {
	return <div className="property">
		<div className="order-title">
			<p className="title">Order #{order.id}</p>
			{/* <p className="">Ordered {moment(order.created_at).format("DD MMMM YYYY")}| Due 07 August 2021</p> */}
			<p className="">Ordered {moment(order.created_at).format("DD MMMM YYYY")}</p>
		</div>

		<div className="product order">
			<div className="stepper-div">
				<div className="wrapper-progressBar">
					<ul className="progressBar">
						{
							order.status === ORDER_CANCELLED
								? <>
									<li className="active">{ORDER_PENDING}</li>
									<li className="active">{ORDER_CANCELLED}</li>
									<li>{ORDER_DISPATCHED}</li>
									<li>{ORDER_COMPLETED}</li>
								</>
								:
								[ORDER_PENDING, ORDER_ORDERED, ORDER_DISPATCHED, ORDER_COMPLETED].map((status, index, array) => {
									const statusIndex = array.findIndex(st => st === order.status);
									return <li className={index <= statusIndex ? "active" : ""} key={status}>{status}</li>
								})
						}
					</ul>
				</div>
			</div>
			{
				order.lineItems
				&& order.lineItems
					.filter(lineItem => lineItem.product)
					.map((lineItem, index) => <ProductItem key={index} product={lineItem.product} variant={lineItem.variant} />)
			}
		</div>
	</div >
};

const ProductItem = ({ product, variant }) => {
	const imageSrc = useMemo(() => {
		const selectedVariant = (product.variants || []).find(({ title }) => title === variant);
		const result = selectedVariant && selectedVariant.images && selectedVariant.images[0]
			? (selectedVariant.images[0].formats && selectedVariant.images[0].formats.thumbnail) || { url: selectedVariant.images[0].url }
			: product.images && product.images[0]
				? (product.images[0].formats && product.images[0].formats.thumbnail) || { url: product.images[0].url }
				: {};

		return result.url || "";
	}, [product, variant])

	return <div className="product-left-div">
		<div className="product-image">
			<img src={imageSrc} alt="product" />
		</div>
		<div className="product-desc">
			<p className="title">{product.title}</p>
			<div className="subtitle" dangerouslySetInnerHTML={{ __html: formatDescriptionData(getProductDescriptionData(product)).split("</p>")[0] + "</p>" }} />
		</div>
	</div>

}

export default withRouter(ProfileView);
