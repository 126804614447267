import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import Dropdown from "../../assets/Dropdown";

const DEFAULT_OPTION = "_default";

function groupVariants(variants = []) {
	const extractOptionName = variant => String(variant.option || DEFAULT_OPTION)
		.trim()
		.toLowerCase();
	const result = {};
	for (const variant of variants) {
		if (!variant.title) continue;
		const optionName = extractOptionName(variant);
		if (!result[optionName]) result[optionName] = [];
		result[optionName].push(variant);
	}
	return result;
}

export default function VariantPicker({ product, variant, onChange }) {

	// Group up the variants by their option.
	const variantOptions = useMemo(() => groupVariants(product.variants), [product.variants]);

	return <div className="variant-picker">

		{/* Iterate the variant options, rendering a picker for each. */}
		{Object.entries(variantOptions).map(([option, variants]) => {
			return <div key={option} className="variant-option flexy align-items-center mt-2">
				<label className="variant-option-name">{option === DEFAULT_OPTION ? "Option" : option}:</label>
				<div>
					<Dropdown
						itemKey="title"
						options={variants.map(({ title }) => ({ title }))}
						value={variant || ""}
						onChange={({ title }) => onChange(title)}
					/>
				</div>
			</div>
		})}

	</div>
}

function groupOptions(options) {
	const result = {};
	for (const option of options) {
		if (!result[option.name]) result[option.name] = [];
		result[option.name].push(option.value);
	}
	return result;
}

function variantTitleToValues(title) {
	const result = {}
	if (!title) return result;
	title.split(" | ").forEach(optionString => {
		const [key, value] = optionString.split(":");
		result[key] = value;
	});
	return result;
}

export function VariantOptionPicker({ product, variant, onChange }) {

	const variants = product.variants || [];

	// Generate the variant options.
	const variantOptions = groupOptions(product.options || []);
	const [values, setValues] = useState(variant ? variantTitleToValues(variant.title) : {});

	// Set the values if the selected variant changes.
	useEffect(() => {
		setValues(variantTitleToValues(variant ? variant.title : null));
	}, [variant]);

	function onValueChange(value, option) {
		// Set the values in state.
		const newValues = { ...values, [option]: value }
		setValues(newValues);
		// Work out which variant we need to change to.
		let fallback;
		const variantMatch = variants.find(variant => {
			const variantValues = variantTitleToValues(variant.title);
			// First see if at least the property we just changed will match.
			if (variantValues[option] === value && !fallback) {
				// If so, use it as our fallback.
				fallback = variant;
			}
			// Otherwise check the other properties.
			for (const key in newValues) {
				if (newValues[key] !== variantValues[key]) return false;
			}
			// It passed!
			return true;
		});
		// Set it.
		if (variantMatch) {
			onChange(variantMatch.title);
		} else if (fallback) {
			onChange(fallback.title);
		} else {
			onChange(variants[0].title);
		}
	}

	return <div className="variant-picker">

		{/* Iterate the variant options, rendering a picker for each. */}
		{Object.entries(variantOptions).map(([name, options]) => {
			return <div key={name} className="variant-option flexy align-items-center mt-2">
				<label className="variant-option-name">{name}:</label>
				<div>
					<Dropdown
						itemKey="value"
						options={options.map(value => ({ value }))}
						value={{ value: values[name] || "" }}
						onChange={({ value }) => onValueChange(value, name)}
					/>
				</div>
			</div>
		})}

	</div>
}


