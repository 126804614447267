import React, { useEffect, useContext, useRef, useState } from "react";
import { NavLink, useParams, Redirect } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { HeroImage, ImgIcon, Button } from "../../assets";
import Carousel, { ImageGallery } from "../../assets/Carousel";
import AccordionSection from "../../assets/AccordionSection";
import Accordion from "../../assets/Accordion";
import { useModal } from "../../assets/useModal"
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { propertyLookup } from "../../actions/property";
import {
	clearCart,
	updateCart,
	updateInCart,
} from "../../actions/cart";
import { combineObjects } from "../../helpers";
import CartBar from "./CartBar";
import ProductCard from "./ProductCard";
import { BrandContext } from "../..";
import { helpCircle } from "../../icons";

function categoryPath({ developer, development, property }, path) {
	return `/developer/${developer}/${development}/${property}/${path}`;
};

function filterSlots(slots = [], category = {}) {
	return slots.filter(slot => {
		return category && category.category && slot.category ? slot.category.id === category.category.id : false;
	});
};

function filterCategories(categories, slots) {
	// Create a list of all categories with products
	let catIds = [];
	slots.forEach(slot => {
		const slotProducts = slot.productOptions || slot.productQuantity || slot.sortedProducts;
		if (slot.category && slotProducts.length && !catIds.includes(slot.category.id)) catIds.push(slot.category.id)
	});
	return categories.filter(cat => catIds.includes(cat.category.id));
};

export default function ProductsView() {

	const dispatch = useDispatch();
	const params = useParams();
	const ref = useRef(null);
	const { setModal, openModal } = useModal();
	const { property, developer, development, categories, cart, settings } = useSelector(state => ({
		property: state.property,
		developer: state.developer,
		development: state.development,
		categories: state.settings.categories || [],
		cart: state.cart,
		settings: state.settings || {}
	}));
	// console.log('check store data', property, developer, development, categories, cart, settings);
	const [pageEntered, setPageEntered] = useState(false);

	const brand = useContext(BrandContext);
	const propertyImages = property.images || [];
	const propertyFloorplans = (property.floorplans || []).map(img => img.url);
	const propertyAddress = combineObjects(developer.address, development.address, property.address);
	const defaultPropertyText = "We've selected some great options to choose from, pre-configured to your property's specifications";

	const slots = property.slots || [];
	const propertyUid = property ? property.uid : null;

	useEffect(() => {
		if (propertyUid && cart.property && propertyUid !== cart.property) {
			console.log("Clearing cart.");
			dispatch(clearCart());
		}
		if (propertyUid && !cart.property) {
			console.log("Updating cart property.");
			dispatch(clearCart());
			dispatch(updateCart({ property: propertyUid }));
		}
	}, [cart.property, propertyUid, dispatch]);

	useEffect(() => {
		const to = setTimeout(() => {
			setPageEntered(true);
		}, [600]);
		return () => {
			clearTimeout(to);
		}
	}, []);

	useEffect(() => {
		// Scrolling to the top of the page on mount
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}, []);

	useEffect(() => {
		if (params.property && (!property.uid || property.uid.toLowerCase() !== params.property))
			dispatch(propertyLookup(params.property));
	}, [params.property, property.uid, dispatch]);

	function helpModal() {
		setModal(
			<div className="help-info" dangerouslySetInnerHTML={{ __html: settings.helpPromptText }} />,
			null,
			{ closable: true, className: "modal-small" }
		);
		openModal()
	};

	function floorplansModal() {
		setModal(<ImageGallery images={propertyFloorplans} startPosition={0} />);
		openModal();
	}

	// Redirect back to the first category if there isn't one.
	if (!params.category && categories.length)
		return <Redirect to={categoryPath(params, categories[0].category.slug)} />

	const currentCategory = categories.length ? categories.find(cat => {
		return cat.category ? cat.category.slug === params.category : false
	}) : {};

	const categorySlots = filterSlots(slots, currentCategory);
	const categoriesWithProducts = filterCategories(categories, slots);

	return <div className="products-view" style={{
		opacity: pageEntered ? 1 : 0,
		transition: "opacity 0.5s ease"
	}}>
		<Helmet title="Products" />
		<CartBar bgColor={brand.color1} textColor={brand.color2} />
		<HeroImage
			src={brand.banner ? `${brand.banner.url}` : null}
			overlay={brand.color1}
		>
			<section>
				<div className="section-content">
					<br />
					<br />
					<img
						className="developer-logo"
						src={brand.logo ? `${brand.logo.url}` : null}
						alt={developer.name}
					/>
				</div>
			</section>
			<section className="overflowing">
				<div className="section-content">
					<div className="development-details default-font white">
						{development.name}
					</div>
					<div className="property-details">
						<div className="type" style={{ backgroundColor: brand.color1, color: brand.color2 }}>
							<div className="default-font large" style={{ color: "inherit" }}>
								{Boolean(propertyAddress.line1) && <>{propertyAddress.line1} <br /></>}
								{Boolean(propertyAddress.line2) && <>{propertyAddress.line2} <br /></>}
								{Boolean(propertyAddress.town) && <>{propertyAddress.town} <br /></>}
							</div>
							<div className="default-font" style={{ color: "inherit" }}>{property.type}</div>
							{Boolean(propertyFloorplans.length)
								&& <Button
									className="floorplan-button"
									onClick={() => {
										floorplansModal()
									}}>Show floorplans</Button>
							}
						</div>
						<Carousel items={propertyImages.map(image => ({
							full: image.url,
							small: (image.formats && image.formats.small)
								? image.formats.small.url
								: image.url
						}))} />
					</div>
				</div>
			</section>
		</HeroImage>
		<section className="gray-bg">
			<div className="section-content">
				{Boolean(property.downloads && property.downloads.length) &&
					<div className="download-section text-center">
						<div className="default-font bold">Here are some useful downloads for your property:</div>
						<ul>
							{property.downloads.map(file => <li key={file.id}>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={file.url}
								>
									{file.name}
								</a>
							</li>)}
						</ul>
						<br />
					</div>
				}
				<div className="default-font medium text-center"
					dangerouslySetInnerHTML={{ __html: property.propertyPageText || settings.propertyPageText || defaultPropertyText }}
				/>
				<br />
				<Button style={{ backgroundColor: "#40c9b6", margin: "0 auto" }} onClick={helpModal}>
					<ImgIcon
						style={{
							filter: "invert(1)",
							marginRight: "5px"
						}}
						cursor="pointer"
						className="align-center"
						src={helpCircle}
						size={16}
					/>
					<span className="align-center">Help</span>
				</Button>
				<br />
				<div className="default-font bold text-center">
					Select the system you're interested in
				</div>
				<br />
				<br />
				<div className="slider" ref={ref}>
					<div className="inner text-center">
						{categoriesWithProducts.map(cat => {
							const category = cat.category ? cat.category : {};
							const isActive = category.slug === params.category;
							let name = category.name;
							if (category.name.includes(" ")) {
								name = category.name.split(" ").map(n => <div key={n}>{n}</div>)
							};
							return (
								<NavLink
									key={cat.id}
									className="slide text-center pointer align-center"
									activeClassName="selected"
									to={categoryPath(params, category.slug)}
									style={isActive ? {
										borderBottom: `10px solid ${brand.color1}`
									} : {}}
									onClick={() => {
										setTimeout(() => {
											let element = ref.current;
											if (!element) return;
											element = element.getBoundingClientRect();
											let moveTo = element.top - element.height;
											if (window.scrollY < moveTo) window.scrollTo({
												top: moveTo,
												left: 0,
												behavior: 'smooth'
											})
										}, 600);
									}}
								>
									<ImgIcon
										size={65}
										src={category.image ? category.image.url : ""}
										cursor="pointer"
									/>
									<div style={{ height: "60px" }}>
										<div className="absolute-center default-font uppercase bold">{name}</div>
									</div>
								</NavLink>
							)
						})}
					</div>
				</div>
			</div>
		</section>
		<br />
		<br />
		<section style={{ minHeight: "50vh" }}>
			<SwitchTransition>
				<CSSTransition
					key={Object.keys(currentCategory).length ? currentCategory.category.id : 0}
					classNames="category-fade"
					appear={true}
					timeout={300}
				>
					<Accordion>
						{categorySlots.map(slot => {

							// This will be our eventual stack of components to render.
							const components = [];

							// If there is one installed already, add it.
							if (slot.installedProduct) components.push(<ProductCard
								installed={true}
								key={slot.installedProduct.id}
								reference={slot.installedProduct.id}
								onHelp={helpModal}
							/>);

							if (slot.productOptions) {
								components.push(
									...slot.productOptions
										.filter(product => !(
											slot.installedProduct && slot.installedProduct.id === product.id
										))
										.map(product => <BasicSlot
											key={product.id}
											slot={slot}
											product={product}
											cart={cart}
											onHelp={helpModal}
										/>)
								)
							} else if (slot.productQuantity) {
								components.push(
									...slot.productQuantity
										.map(item => <QuantitySlot
											key={item.id}
											slot={slot}
											item={item}
											cart={cart}
											onHelp={helpModal}
										/>)
								)
							} else if (slot.sortedProducts) {
								components.push(
									...slot.sortedProducts
										.filter(sort => !(
											slot.installedProduct && slot.installedProduct.id === sort.product.id
										))
										.sort((a, b) => {
											return a.order - b.order
										})
										.map(sort => <BasicSlot
											key={sort.product.id}
											slot={slot}
											product={sort.product}
											cart={cart}
											onHelp={helpModal}
										/>)
								)
							}
							return <AccordionSection key={slot.id} name={slot.name}>
								{components.filter(Boolean)}
							</AccordionSection>
						})}
					</Accordion>
				</CSSTransition>
			</SwitchTransition>
		</section>
	</div>
};

function BasicSlot({ slot, product, cart, onHelp }) {

	const dispatch = useDispatch();

	return <ProductCard
		disabled={Boolean(slot.installedProduct)}
		product={product}
		onHelp={onHelp}
		maxQuantity={1}
		onQuantityChange={(variant, quantity, installation) => dispatch(updateInCart(product, variant, quantity, installation, 1))}
	/>
};

function QuantitySlot({ slot, item, cart, onHelp }) {

	const dispatch = useDispatch()

	const product = item.product;

	return <ProductCard
		key={product.id}
		product={product}
		onHelp={onHelp}
		maxQuantity={item.quantity}
		onQuantityChange={(variant, quantity, installation) => dispatch(updateInCart(product, variant, quantity, installation, item.quantity))}
	/>
};
