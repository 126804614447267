import React, { useMemo, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Button } from "../assets";
import { API_ROOT, EMAIL_REGEX, PASSWORD_REGEX, PHONE_REGEX } from "../constants";
import { searchWhite } from "../icons";

function contactFormValidation({ email, telephone, password, confirm }) {
	// Check if email exists and valid.
	if (!email || !EMAIL_REGEX.test(email))
		return "Please provide valid email address.";
	// Check if password exists and valid.
	if (!password || !PASSWORD_REGEX.test(password))
		return "Password must be between 8 and 40 characters and contain letters and numbers.";
	// Check if password matched.
	if (!password || !confirm || !Boolean(password === confirm))
		return "Passwords have to match.";
	// Check if telephone number is present and correct.
	if (!telephone || !PHONE_REGEX.test(telephone))
		return "Incorrect telephone number.";

	return null;
};

export default function ContactSection({ onBack, property = {} }) {

	const [formData, setFormData] = useState({});
	const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);
	const settings = useSelector(state => state.settings || {});

	const address = useMemo(() => {
		return [property.line1, property.line2, property.postcode, property.town].filter(Boolean).join(", ")
	}, [property])

	return (
		<div className="section-content text-center contact-section">
			<br />
			<div className="select-page-text" dangerouslySetInnerHTML={{ __html: settings.selectPageContactDetailsText }} />
			<div>
				<Button
					style={{ margin: "0 auto" }}
					className="default-font medium back-btn"
					onClick={onBack}
				>
					<span className="align-center">Back to code lookup</span>
					<img className="align-center" src={searchWhite} alt="search icon" />
				</Button>
			</div>
			<br />
			<br />
			{success
				?
				<div className="contact-success default-font medium">
					<p>Thank you for getting in touch!</p>
					<p>We've sent you an email with your property's code.</p>
				</div>
				:
				<form className="user-contact-form" onSubmit={e => {
					e.preventDefault();
					// If validation error, return.
					const formError = contactFormValidation(formData);
					if (Boolean(formError)) {
						setError(formError);
						return null;
					} else {
						setError(null); // Clear existing error.
					}
					// If this submitting fot the first time set setSubmitting to true.
					if (!submitting) setSubmitting(true);

					axios.post(`${API_ROOT}/properties/get-code`, { ...formData, pId: property.id })
						.then(res => {
							// All good, show success message
							setSuccess(true);
						}).catch(err => {
							console.error("Error getting properties: ", err);
							setError("Error submitting form.");
						}).finally(() => {
							// Allow submitting
							setSubmitting(false);
						});
				}}>
					<div className="default-font medium selected-address">
						{address}
					</div>
					<input
						placeholder="First name"
						name="firstName"
						type="text"
						required
						className="default-font medium"
						value={formData.firstName || ""}
						onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
					/>
					<input
						placeholder="Last name"
						name="lastName"
						type="text"
						required
						className="default-font medium"
						value={formData.lastName || ""}
						onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
					/>
					<input
						placeholder="Email"
						name="email"
						type="text"
						required
						className="default-font medium"
						value={formData.email || ""}
						onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
					/>
					<input
						placeholder="Telephone"
						name="telephone"
						type="text"
						required
						className="default-font medium"
						value={formData.telephone || ""}
						onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
					/>
					<input
						placeholder="Password"
						name="password"
						type="password"
						required
						className="default-font medium"
						value={formData.password || ""}
						onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
					/>
					<input
						placeholder="Confirm password"
						name="confirm"
						type="password"
						required
						className="default-font medium"
						value={formData.confirm || ""}
						onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })}
					/>
					<div className="terms-checkbox-wrapper">
						<input
							id="termsAccepted"
							name="termsAccepted"
							type="checkbox"
							required
							className="align-center"
							value={formData.termsAccepted || false}
							onChange={e => setFormData({ ...formData, [e.target.name]: e.target.checked })}
						/>
						<label className="default-font medium align-center" htmlFor="termsAccepted">
							I accept Avande Connect <a href="https://avandeconnect.com/policies/terms-of-service" target="_blank" rel="noopener noreferrer">terms and conditions</a>
						</label>
					</div>
					<Button
						loading={submitting}
						disabled={submitting}
						type="submit"
						className="default-font medium"
					>
						Submit
					</Button>
				</form>
			}
			{Boolean(error) && <div className="error">{error}</div>}
			<br />
			<br />
		</div>
	)
}
