import "core-js";
import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, useLocation } from "react-router-dom";
import { createRoot } from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
// Redux
import { applyMiddleware, createStore, compose } from "redux";
import { Provider, useSelector, useDispatch } from "react-redux";
import thunk from "redux-thunk";
import { Helmet } from "react-helmet";
import reducers, { initialState } from "./reducers";
// import { cartLookup } from "./actions/cart";
import { requestCurrentUser } from "./actions/session";
import { getSettings } from "./actions/settings";
// Components
import HomeView from "./views/Home";
import PropertyView from "./views/Property";
import Logger from "./assets/Logger";
// Style imports
import "./style/main.scss";
import { combineObjects, getCookies } from "./helpers";
import { COOKIE_NAME, DEFAULT_BRAND } from "./constants";
import { ModalProvider } from "./assets/useModal";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Checkout from "./views/Checkout";
import LoginView from "./views/Login";
import ProfileView from "./views/Profile";
import { saveState } from "./helpers/hooks/localStorage";

// Init the redux store.
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	reducers,
	initialState,
	composeEnhancers(
		applyMiddleware(thunk)
	)
);

store.subscribe(() => saveState("cart", store.getState()["cart"] || {}));

export const BrandContext = createContext();

function getLocationKey(pathname) {
	if (pathname === "/") return "main";
	if (/^\/account/.test(pathname)) return "account";
	if (/^\/developer\//.test(pathname)) return "property";
	return "misc";
}

function AppWrapper() {

	const dispatch = useDispatch();
	// const { user, ready } = useSelector(state => state.session);
	const location = useLocation();
	const locationKey = getLocationKey(location.pathname);
	const [pageFade, setPageFade] = useState(true);
	const [open, setOpen] = useState(false);
	const { developerBrand, developmentBrand } = useSelector(state => ({
		developerBrand: state.developer.brand,
		developmentBrand: state.development.brand
	}));

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	// If the app starts, and there is a token in the cookie, go get the user.
	useEffect(() => {
		const cookies = getCookies();
		if (cookies[COOKIE_NAME])
			dispatch(requestCurrentUser());
	}, [dispatch]);

	useEffect(() => {
		window.scrollTo(0, 0);
		setPageFade(true);
		const timeout = setTimeout(() => {
			setPageFade(false);
		}, 300);
		return () => clearTimeout(timeout);
	}, [locationKey]);

	useEffect(() => {
		dispatch(getSettings());
	}, [dispatch]);

	return <ModalProvider>
		<BrandContext.Provider value={combineObjects(DEFAULT_BRAND, developerBrand, developmentBrand)}>
			<div id="app">
				<Helmet
					defaultTitle="Avande Connect"
					titleTemplate="Avande Connect | %s"
				/>
				<Navbar
					isDrawerOpen={open}
					handleDrawerClose={handleDrawerClose}
					handleDrawerOpen={handleDrawerOpen}>
				</Navbar>
				<Switch>
					<Route
						path="/developer/:developer/:development/:property/:category?"
						component={PropertyView}
					/>
					<Route
						path="/checkout"
						exact
						component={Checkout}
					/>
					<Route
						path="/account"
						exact
						component={ProfileView}
					/>
					<Route
						path="/login"
						component={LoginView}
					/>
					<Route
						path="/developer/:developer"
						exact
						component={HomeView}
					/>
					<Route
						path="/developer/:developer/:development"
						exact
						component={HomeView}
					/>
					<Route
						path="/"
						component={HomeView}
					/>
				</Switch>
				<Logger />
				<div className="transition-overlay" style={{
					opacity: pageFade ? 1 : 0
				}} />
				<Footer></Footer>
			</div>
		</BrandContext.Provider>
	</ModalProvider>

}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
	<Provider store={store}>
		<BrowserRouter>
			<AppWrapper />
		</BrowserRouter>
	</Provider>
);

serviceWorker.unregister();
