import { useEffect, useRef } from "react";
import $ from "jquery";
import moment from "moment";

export function getCookies() {
	let result = {};
	document.cookie
		.split(";")
		.map(pair => pair.trim().split("="))
		.forEach(([name, value]) => {
			result[name] = value
		});
	return result;
}

export function setCookie({ key, value, path, expires }) {
	const cookie = [
		[key, value || ""],
		path ? ["path", path] : null,
		expires ? ["expires", moment(expires).format("ddd, DD MMM YYYY HH:MM:SS Z")] : null
	]
		.filter(c => c)
		.map(([key, value]) => `${key}=${value}`)
		.join("; ");
	document.cookie = cookie;
}

export function getQuery(removeFromQueryString = []) {
	// Build the object from the query string.
	let result = parseQueryString(window.location.search);
	// If query properties are to be removed, do it here.
	if (removeFromQueryString.length) {
		let allowedValues = { ...result };
		removeFromQueryString.forEach(key => delete allowedValues[key]);
		let newQs = objectToQuery(allowedValues);
		let newUrl = window.location.href.replace(window.location.search, newQs ? "?" + newQs : "");
		// Replace the actual history state to one without the code.
		if (window.history && window.history.replaceState)
			window.history.replaceState({}, "", newUrl);
	}
	return result;
}

export function objectToQuery(object) {
	return Object.entries(object)
		.filter(([, value]) => value !== null)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join("&");
}

export function parseQueryString(qs = "") {
	const result = {}
	qs.replace(/^\?/, "")
		.split("&")
		.map(pair => pair.split("="))
		.forEach(([key, value]) => {
			if (value)
				result[decodeURIComponent(key)] = decodeURIComponent(value);
		});
	return result;
}

export function errorResponse(error) {
	let message;
	if (error.response) {
		message = error.response.data.message;
		if (message instanceof Array) {
			message = message.map(m => m.messages.map(m2 => m2.message).join(" ")).join(" ");
		}
	} else if (error.request) {
		message = "No response from server."
	} else if (error.message) {
		message = error.message;
	} else {
		message = "Unknown error.";
		console.error("Unknown error: ", error);
	}
	return message;
}

export function rid(length = 20) {
	let id = [];
	let charset = "abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	for (let i = 0; i < length; i++) {
		id.push(charset[Math.round(Math.random() * (charset.length - 1))]);
	}
	return id.join("");
}


// Gets the unique objects from passed array
export function getUnique(arr, comp) {

	// store the comparison values in array
	const unique = arr.map(e => e[comp])

		// store the indexes of the unique objects
		.map((e, i, final) => final.indexOf(e) === i && i)

		// eliminate the false indexes & return unique objects
		.filter((e) => arr[e]).map(e => arr[e]);

	return unique;
}

export function combineObjects() {
	function removeNulls(object) {
		const result = {}
		Object.entries(object).forEach(([key, value]) => {
			if (value) result[key] = value;
		});
		return result;
	}
	return Object.assign({}, ...[...arguments].map(arg => arg ? removeNulls(arg) : {}))
}

// Get the price of the product variant.
export function getVariantPrice(variant, product, quantity = 1, installation) {
	if (product.isPackage) variant = getPackageVariant(variant, product);
	// Check for required parameters.
	if (!variant) console.error("Missing required property variant.");
	// Set base price from the variant.
	let price = variant.price;
	// If product is provided and has installation change add up.
	if (product && product.installationCharge && installation) price += product.installationCharge;
	// Apply discount, if any.
	if (product && Boolean(product.discountPercentage)) price -= price * product.discountPercentage / 100;
	// Multiply by quantity.
	price = price * quantity;

	return price;
}

// Construct a variant for package product.
export function getPackageVariant(variant, packageProduct) {
	// If variant passed as string.
	if (
		(typeof variant === 'string' || variant instanceof String)
		&& packageProduct && packageProduct.variants && packageProduct.variants.length
	) {
		variant = packageProduct.variants.find(v => v.title === variant);
	}
	// Either package variant passed as string or no variant passed.
	else if (
		(
			(typeof variant === 'string' || variant instanceof String)
			&& variant === `Product Package ${packageProduct.id}`
		) || !variant
	) {
		variant = {
			"title": "Product Package",
			"option": "product-package",
			"price": 0,
			"position": 1,
			"images": []
		};
		variant.title += ` ${packageProduct.id}`;
		variant.option += `-${packageProduct.id}`;
	}

	if (packageProduct && packageProduct.isPackage && packageProduct.packageItems) {
		let price = 0;
		for (const { product, quantity } of packageProduct.packageItems) {
			if (product.variants && product.variants[0]) price += getVariantPrice(product.variants[0], product, quantity);
		}
		variant.price = price;
	}

	return variant;
}

// Get the variant matching variant title.
export function getSelectedProductVariant(variantTitle, product) {
	// Detect if package then prepare variant
	if (product.isPackage)
		return getPackageVariant(variantTitle, product);

	return product.variants.find(obj => obj.title === variantTitle);
}

export function formatDescriptionData(data) {
	if (!data) return "";
	let result = "";
	$(`<div>${data}</div>`).find("> *").each(function () {
		let elem = $(this);
		if (elem.text().trim() !== "Description") result += elem[0].outerHTML;
	})
	return result;
}

export function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

export function totalCart(cart) {
	if (!cart) return 0;
	let total = 0;
	for (const item of cart.items) {
		let itemVariant;
		// Extract the variant chosen from the list.
		if (item.product.isPackage) {
			itemVariant = getPackageVariant(item.variant, item.product);
		} else {
			itemVariant = item.product.variants.find(variant => variant.title === item.variant);
		}
		// Skip if none found.
		if (!itemVariant) continue;
		// And total it up.
		total += getVariantPrice(itemVariant, item.product, item.quantity, item.installation);
	}
	return total;
}

export function idIfy(item) {
	if ([null, undefined, ""].includes(item)) {
		return null;
	} else if (item.constructor === Number) {
		return item;
	} else if (item.constructor === Object) {
		return idIfy(item.id);
	} else if (item.constructor === String) {
		let num = Number(item);
		return isNaN(num) ? null : num;
	} else {
		return null;
	}
}
