import { totalCart } from "../helpers";

export const CART_UPDATE_START = "CART_UPDATE_START"
export const CART_UPDATE_SUCCESS = "CART_UPDATE_SUCCESS"
export const CART_UPDATE_FAIL = "CART_UPDATE_FAIL";

export const CART_CLEAR = "CART_CLEAR";

export function updateCart(changes = {}) {
	return {
		type: CART_UPDATE_SUCCESS,
		changes
	}
}

export function clearCart() {
	return {
		type: CART_CLEAR
	}
}

export function updateInCart(product, variant, quantity, installation, max) {
	return function (dispatch, getState) {
		const { cart } = getState();

		// Update the cart items.
		let itemUpdated = false;
		const newItems = cart.items
			.map(item => {
				// If the item is the same product, of the same variant..
				if (item.product.id === product.id && item.variant === variant) {
					// We need to merge the changes.
					itemUpdated = true;
					return {
						...item,
						installation: installation === undefined ? item.installation : installation,
						quantity: quantity <= item.max ? quantity : item.max
					}
				} else {
					return item;
				}
			})
			// We also now need to remove any items set to 0 quantity.
			.filter(item => item.quantity);
		// If nothing was updated, we need to add the item to the cart.
		if (!itemUpdated && quantity) newItems.push({
			product,
			variant,
			quantity: quantity <= max ? quantity : max,
			installation: Boolean(installation),
			max
		});

		// Create a new cart object with the changes.
		const newCart = {
			...cart,
			items: newItems
		}

		// Total it.
		const total = totalCart(newCart);

		// Dispatch the update.
		dispatch(updateCart({
			items: newItems,
			total
		}));
	}
}
