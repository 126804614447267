import React from "react";
import { EMAIL_LOGIN, InputField } from ".";
import { Button } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { passwordReset, setSessionData } from "../../actions/session";
import { useState } from "react";
import { PASSWORD_REGEX } from "../../constants";

function resetPasswordFormValidation({ password, confirmPassword }) {
	return {
		password: password
			? PASSWORD_REGEX.test(password) ? false : "Password must be between 8 and 40 characters and contain letters and numbers."
			: "Password is required.",
		confirmPassword: Boolean(password === confirmPassword) ? false : "Passwords have to match."
	}
};

export default function ResetPassword({
	values,
	errors,
	onInputChange,
	onViewChange,
	onError
}) {

	const dispatch = useDispatch();

	const { query, loading } = useSelector(state => ({
		query: state.session.query,
		loading: state.session.loading
	}));

	const [succeeded, setSucceeded] = useState(false);
	const [code] = useState(query.code);
	const [attemptMade, setAttemptMade] = useState(false);

	useEffect(() => {
		if (query.resetPassword) dispatch(setSessionData({
			query: {
				...query,
				forEmailConfirmation: null,
				resetPassword: null,
				code: null
			}
		}));
	}, [dispatch, query]);

	useEffect(() => {
		// Checking for input errors.
		const newErrors = {};
		for (const [key, value] of Object.entries(resetPasswordFormValidation(values))) {
			if (errors && errors[key] !== value) newErrors[key] = value;
		}
		if (Object.keys(newErrors).length) onError(newErrors);
	}, [errors, onError, values]);

	// Get error messages.
	const errorMessages = Object.keys(errors).map(key => errors[key]).filter(Boolean);

	console.log("Errors", errorMessages);

	return <div>
		<form onSubmit={e => {
			e.preventDefault();
			// If this submitting fot the first time set attemptMade to true.
			if (!attemptMade) setAttemptMade(true);
			// If no validation errors, submit.
			if (errorMessages.length) return null;
			dispatch(passwordReset({
				password: values.password,
				code
			}, error => {
				if (!error) setSucceeded(true);
			}));
		}}>
			<h4>Set a New Password</h4>
			<div className="info">
				Enter the password you want to use from now on.
			</div>
			<InputField
				attemptMade={attemptMade}
				name="password"
				placeholder="Password"
				type="password"
				values={values}
				errors={errors}
				onInputChange={onInputChange}
			/>
			<InputField
				attemptMade={attemptMade}
				name="confirmPassword"
				placeholder="ConfirmPassword"
				type="password"
				values={values}
				errors={errors}
				onInputChange={onInputChange}
			/>
			{!succeeded && <div className="input-field">
				<Button style={{ width: "100%" }} type="submit" loading={loading}>Confirm</Button>
			</div>}
		</form>
		{succeeded && <>
			<div className="info">
				All done! Press the button below to return to log in.
			</div>
			<div className="input-field login-button">
				<Button
					className=" default-font medium"
					onClick={e => onViewChange(EMAIL_LOGIN)}
				>Log in</Button>
			</div>
		</>}
	</div>
}
