import { initialState } from ".";
import {
    SET_SESSION_DATA,
    LOGIN_REQUEST_START,
    LOGIN_REQUEST_FAIL,
    LOGIN_REQUEST_SUCCESS,
    SIGNUP_REQUEST_START,
    SIGNUP_REQUEST_FAIL,
    SIGNUP_REQUEST_SUCCESS,
    CURRENT_USER_REQUEST_FAIL,
    CURRENT_USER_REQUEST_START,
    CURRENT_USER_REQUEST_SUCCESS,
    REQUEST_PASSWORD_RESET_START,
    REQUEST_PASSWORD_RESET_FAIL,
    REQUEST_PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_START,
    SEND_EMAIL_CONFIRMATION_START,
    SEND_EMAIL_CONFIRMATION_SUCCESS,
    SEND_EMAIL_CONFIRMATION_FAIL,
    LOGOUT,
    CLEAR_LOGIN_ERROR,
    UPDATE_ME_START,
    UPDATE_ME_SUCCESS,
    UPDATE_ME_FAIL
} from "../actions/session";

export default function reducer(session = initialState.session, action) {
    switch (action.type) {
        case SET_SESSION_DATA:
            // Set the data, excluding some reserved values, such as user.
            let { user, ...data } = action.data;
            return {
                ...session,
                ...data
            }

        case PASSWORD_RESET_START:
        case REQUEST_PASSWORD_RESET_START:
        case SIGNUP_REQUEST_START:
        case CURRENT_USER_REQUEST_START:
        case LOGIN_REQUEST_START:
        case SEND_EMAIL_CONFIRMATION_START:
            return {
                ...session,
                loginError: null,
                loading: true
            }

        case CURRENT_USER_REQUEST_SUCCESS:
            return {
                ...session,
                loading: false,
                loginError: null,
                token: action.token,
                user: action.user,
                ready: true
            }

        case LOGIN_REQUEST_SUCCESS:
            return {
                ...session,
                loading: false,
                loginError: null,
                user: action.user,
                token: action.token,
                ready: true
            }

        case REQUEST_PASSWORD_RESET_SUCCESS:
        case SEND_EMAIL_CONFIRMATION_SUCCESS:
        case PASSWORD_RESET_SUCCESS:
            return {
                ...session,
                loginError: null,
                loading: false,
            }

        case PASSWORD_RESET_FAIL:
        case REQUEST_PASSWORD_RESET_FAIL:
        case SEND_EMAIL_CONFIRMATION_FAIL:
            return {
                ...session,
                loginError: action.error || "Unknown error.",
                loading: false,
            }

        case SIGNUP_REQUEST_FAIL:
        case CURRENT_USER_REQUEST_FAIL:
        case LOGIN_REQUEST_FAIL:
            return {
                ...session,
                loginError: action.error || "Unknown error.",
                loading: false,
                ready: true
            }

        case SIGNUP_REQUEST_SUCCESS:
            return {
                ...session,
                loading: false,
                loginError: null,
                needsConfirmation: true
            }

        case LOGOUT:
            return {
                ...session,
                loading: false,
                loginError: null,
                user: null,
                token: null
            }

        case CLEAR_LOGIN_ERROR:
            return {
                ...session,
                loginError: null,
            }

        case UPDATE_ME_START:
            return {
                ...session,
                updateError: null,
                updating: true
            }

        case UPDATE_ME_SUCCESS:
            return {
                ...session,
                updating: false,
                user: action.user
            }

        case UPDATE_ME_FAIL:
            return {
                ...session,
                updateError: action.error,
                updating: false
            }

        default:
            return session;
    }
}
