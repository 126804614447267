import axios from "axios";
import { API_ROOT } from "../constants";
import { errorResponse } from "../helpers";

export const PROPERTY_LOOKUP_START = "PROPERTY_LOOKUP_START";
export const PROPERTY_LOOKUP_SUCCESS = "PROPERTY_LOOKUP_SUCCESS";
export const PROPERTY_LOOKUP_FAIL = "PROPERTY_LOOKUP_FAIL";
export const LOOKUP_CLEAR = "LOOKUP_CLEAR";

export function propertyLookup(uid) {
	return function (dispatch) {

		dispatch({
			type: PROPERTY_LOOKUP_START,
			uid
		});

		axios.get(`${API_ROOT}/properties/${uid}`).then(res => res.data).then(property => {
			dispatch({
				type: PROPERTY_LOOKUP_SUCCESS,
				property
			});
		}).catch(err => {
			const error = errorResponse(err);
			dispatch({
				type: PROPERTY_LOOKUP_FAIL,
				error
			});
		});
	}
}

export function clearLookup() {
	return function (dispatch) {
		dispatch({
			type: LOOKUP_CLEAR
		});
	}
}
