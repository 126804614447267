import { initialState } from ".";
import { PROPERTY_LOOKUP_SUCCESS, LOOKUP_CLEAR } from "../actions/property";
import { DEVELOPER_LOOKUP_FAIL, DEVELOPER_LOOKUP_START, DEVELOPER_LOOKUP_SUCCESS } from "../actions/developer";

export default function (state = initialState.developer, action) {
	switch (action.type) {

		case DEVELOPER_LOOKUP_START:
			return {
				...state,
				lookupError: null,
				loading: true
			}
		case DEVELOPER_LOOKUP_FAIL:
			return {
				...state,
				lookupError: action.error,
				loading: false
			}
		case DEVELOPER_LOOKUP_SUCCESS:
			return {
				...state,
				loading: false,
				...action.developer
			}

		case PROPERTY_LOOKUP_SUCCESS:
			return {
				...state,
				loading: false,
				...action.property.development.developer
			}

		case LOOKUP_CLEAR: 
			return {
				...state,
				uid: null
			};

		default:
			return state
	}
}