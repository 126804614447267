import React, { useState, useEffect } from "react";

export default function Accordion({ children }) {

    const [open, setOpen] = useState(null);

    useEffect(() => {
        let to = setTimeout(() => {
            setOpen(0);
        }, [500]);
        return () => {
            clearTimeout(to);
        }
    }, []);

    // if (!children) return null;

    return <div className="slct-accordion">
        {children.map((Component, index) => {
            let isOpen = index === open;
            function setSectionOpen(open) {
                if (open) {
                    setOpen(index);
                } else {
                    if (isOpen) setOpen(null);
                }
            }
            return React.cloneElement(Component, {
                open: isOpen,
                setOpen: setSectionOpen,
                key: index
            });
        })}
    </div>
}
