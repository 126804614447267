import React from "react";
import { EMAIL_LOGIN } from ".";
import { Button } from "../../assets";

export default function AccountExistsUnconfirmed({ values, onViewChange }) {

	return <div>
		<h4>You've Already Got An Account!</h4>
		<div className="info">
			But, your email hasn't been confirmed.
			<br /><br />
			We've just sent a new confirmation email to <b>{values.email}</b>. Click the link in the email to confirm your account.
			<br /><br />
			Click the button below to go back to log in.
		</div>
		<div className="input-field login-button">
			<Button className="default-font medium" onClick={e => onViewChange(EMAIL_LOGIN)}>Log in</Button>
		</div>
	</div>
}
