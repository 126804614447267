import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { DEFAULT_BRAND } from "../../constants";
import { Link } from "react-router-dom";

export function getItemsCount(items = []) {
    let total = 0;

    items.forEach(item => {
        total += (item.quantity || 0);
    })

    return total;
};

export default function CartBar({ bgColor = DEFAULT_BRAND.color1, textColor = DEFAULT_BRAND.color2 }) {

    const { items, total } = useSelector(state => state.cart);
    const itemsCount = getItemsCount(items);
    const ref = useRef(null);

    // useEffect(() => {
    //     console.log('window', window.pageYOffset)
    // }, [window.pageYOffset])

    useEffect(() => {
        function getPosition() {
            let boxTop = document.querySelector("#upper-header")
                ? document.querySelector("#upper-header").getBoundingClientRect().height
                : 0;

            let boxBottom = document.querySelector("#lower-header")
                ? document.querySelector("#lower-header").getBoundingClientRect()
                : { top: 0, height: 0 };

            // return boxTop + (boxBottom.top > boxBottom.height ? boxBottom.height : 0);
            return Boolean(boxBottom.top && boxBottom.height) ? boxBottom.top + boxBottom.height : boxTop;
        };

        function setPosition() {
            ref.current.style.top = getPosition() + "px"
        };

        setPosition();

        const interval = setInterval(() => {
            setPosition();
        }, 250);

        window.addEventListener("scroll", setPosition);

        return () => {
            window.removeEventListener("scroll", setPosition);
            clearInterval(interval);
        };
    }, [ref]);

    return (
        <div ref={ref} className="cart-bar shadow" style={{ backgroundColor: bgColor, color: textColor }}>
            <div>
                <div className="cart-content">
                    <b>{itemsCount}</b> item{itemsCount !== 1 ? "s" : ""} - <b>£{(total / 100).toFixed(2)}</b>
                    {(itemsCount > 0) &&
                        <Link
                            className="default-font bold"
                            to="/checkout"
                        >
                            Checkout
                        </Link>
                    }
                </div>
            </div>
        </div>
    )
};
