import axios from "axios";
import { API_ROOT, COOKIE_NAME } from "../constants";
import { setCookie, errorResponse } from "../helpers";
import moment from "moment";
import { addLog } from "./logger";

// Authentication.
export const LOGIN_REQUEST_START = "LOGIN_REQUEST_START";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_FAIL = "LOGIN_REQUEST_FAIL";

export const SIGNUP_REQUEST_START = "SIGNUP_REQUEST_START";
export const SIGNUP_REQUEST_SUCCESS = "SIGNUP_REQUEST_SUCCESS";
export const SIGNUP_REQUEST_FAIL = "SIGNUP_REQUEST_FAIL";

export const REQUEST_PASSWORD_RESET_START = "REQUEST_PASSWORD_RESET_START";
export const REQUEST_PASSWORD_RESET_SUCCESS = "REQUEST_PASSWORD_RESET_SUCCESS";
export const REQUEST_PASSWORD_RESET_FAIL = "REQUEST_PASSWORD_RESET_FAIL";

export const PASSWORD_RESET_START = "PASSWORD_RESET_START";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";

export const CURRENT_USER_REQUEST_START = "CURRENT_USER_REQUEST_START";
export const CURRENT_USER_REQUEST_SUCCESS = "CURRENT_USER_REQUEST_SUCCESS";
export const CURRENT_USER_REQUEST_FAIL = "CURRENT_USER_REQUEST_FAIL";

export const SEND_EMAIL_CONFIRMATION_START = "SEND_EMAIL_CONFIRMATION_START";
export const SEND_EMAIL_CONFIRMATION_SUCCESS = "SEND_EMAIL_CONFIRMATION_SUCCESS";
export const SEND_EMAIL_CONFIRMATION_FAIL = "SEND_EMAIL_CONFIRMATION_FAIL"

export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";

export const LOGOUT = "LOGOUT";
// Misc
export const SET_SESSION_DATA = "SET_SESSION_DATA";

// User update
export const UPDATE_ME_START = "UPDATE_ME_START";
export const UPDATE_ME_SUCCESS = "UPDATE_ME_SUCCESS";
export const UPDATE_ME_FAIL = "UPDATE_ME_FAIL";

/**
 *
 * @param {Object} userData
 * @param {String} userData.email
 * @param {String} userData.password
 * @param {Function} cb
 */
export function login({ email, password }, cb) {
	return function (dispatch, getState) {

		dispatch({
			type: LOGIN_REQUEST_START,
			credentials: { email, password }
		});

		axios.post(`${API_ROOT}/auth/local`, {
			identifier: email,
			password,
		}).then(async ({ data }) => {
			// Success!
			dispatch({
				type: LOGIN_REQUEST_SUCCESS,
				user: data.user,
				token: data.jwt
			});
			// Also set a cookie.
			setCookie({
				key: COOKIE_NAME,
				value: data.jwt,
				path: "/",
				expires: moment().add(1, "month")
			});
			// Run a callback if passed
			if (cb) cb(data.user);
		}).catch(error => {
			const errorMessage = errorResponse(error);
			dispatch({
				type: LOGIN_REQUEST_FAIL,
				error: errorMessage
			});
			dispatch(addLog({
				type: "error",
				text: errorMessage
			}));
		});

	}
}

/**
 *
 * @param {Object} userData
 * @param {String} userData.email
 * @param {String} userData.password
 * @param {String} userData.firstName
 * @param {String} userData.lastName
 * @param {Function} cb
 */
export function signup(userData, cb) {
	return function (dispatch) {
		dispatch({ type: SIGNUP_REQUEST_START });

		axios.post(`${API_ROOT}/auth/local/register`, userData).then(({ data }) => {
			// Success!
			dispatch({
				type: SIGNUP_REQUEST_SUCCESS,
				user: data.user
			});
			// Also set a cookie.
			setCookie({
				key: COOKIE_NAME,
				value: data.jwt,
				path: "/",
				expires: moment().add(1, "month")
			});
			// Run a callback if passed
			if (cb) cb(data.user);
		}).catch(error => {
			dispatch({
				type: SIGNUP_REQUEST_FAIL,
				error: errorResponse(error)
			});
		});

	}
}

/**
 *
 * @param {Object} userData
 * @param {String} userData.email
 * @param {Function} cb Callback called after successful request.
 */
export function requestPasswordReset({ email }, cb) {
	return function (dispatch) {
		dispatch({ type: REQUEST_PASSWORD_RESET_START });

		axios.post(`${API_ROOT}/auth/forgot-password`, {
			email,
		}).then(({ data }) => {
			// Success!
			dispatch({
				type: REQUEST_PASSWORD_RESET_SUCCESS
			});
			if (cb) cb();
		}).catch(error => {
			dispatch({
				type: REQUEST_PASSWORD_RESET_FAIL,
				error: errorResponse(error)
			});
		});
	}
}

// Resetting the password using the passed code.
/**
 *
 * @param {Object} data
 * @param {String} data.password New password to set.
 * @param {String} data.code The code provided in the query string.
 * @param {Function} cb Callback called after a reset. Called regardless of success/fail. If there is an error, the error is the first argument passed to the callback.
 */
export function passwordReset({ code, password }, cb) {
	return function (dispatch) {
		dispatch({ type: PASSWORD_RESET_START });

		axios.post(`${API_ROOT}/auth/reset-password`, {
			code,
			password,
			passwordConfirmation: password,
		}).then(res => res.data).then(data => {
			// Check the user is actually validated.
			if (!data.user.confirmed) {
				let error = "Your password was reset, but your email address has never been confirmed. Go find your welcome email and click the link."
				dispatch({
					type: PASSWORD_RESET_FAIL,
					error
				});
				if (cb) cb(error);
				return;
			}
			dispatch({
				type: PASSWORD_RESET_SUCCESS
			});
			if (cb) cb(null);
		}).catch(error => {
			dispatch({
				type: PASSWORD_RESET_FAIL,
				error: errorResponse(error)
			});
		});
	}
}

export function requestCurrentUser(cb = null) {
	return function (dispatch, getState) {
		// Trigger the start.
		dispatch({
			type: CURRENT_USER_REQUEST_START
		});
		// Get the current token.
		const { session: { token } } = getState();
		// If there isn't one, we can exit early.
		if (!token) return dispatch({
			type: CURRENT_USER_REQUEST_FAIL
		});

		axios.get(`${API_ROOT}/users/me`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		}).then(res => res.data).then(user => {
			dispatch({
				type: CURRENT_USER_REQUEST_SUCCESS,
				user,
				token
			});
			if (cb) cb(user);
		}).catch(error => {
			setCookie({
				key: COOKIE_NAME,
				path: "/",
				value: ""
			});
			dispatch({
				type: CURRENT_USER_REQUEST_FAIL,
				error: errorResponse(error)
			});
			dispatch(addLog({
				type: "error",
				text: errorResponse(error)
			}));
			if (cb) cb(null);
		});
	}
}

export function sendEmailConfirmation(email, cb) {
	return function (dispatch, getState) {
		// Trigger the start
		dispatch({
			type: SEND_EMAIL_CONFIRMATION_START
		});

		axios.post(`${API_ROOT}/auth/send-email-confirmation`, {
			email,
		}).then(res => {
			// Handle success.
			dispatch({
				type: SEND_EMAIL_CONFIRMATION_SUCCESS
			});
			if (cb) cb();
		}).catch(error => {
			dispatch({
				type: SEND_EMAIL_CONFIRMATION_FAIL,
				error: errorResponse(error)
			});
		});
	}
}

export function logout() {
	return function (dispatch, getState) {
		// Reset cookie.
		setCookie({
			key: COOKIE_NAME,
			path: "/",
			value: ""
		});

		// Finally dispatch the logout event.
		dispatch({
			type: LOGOUT
		});
	}
}

// For setting general session data.
export function setSessionData(data) {
	return {
		type: SET_SESSION_DATA,
		data
	}
};

// Updating session user
export function updateMe(changes, cb) {
	return function (dispatch, getState) {
		// Trigger the start
		dispatch({
			type: UPDATE_ME_START
		});

		const {
			session: { token, user }
		} = getState();

		if (!token || !user || !user.id) return dispatch({
			type: UPDATE_ME_FAIL,
			error: "Error updating - Unauthorized"
		});

		axios.put(`${API_ROOT}/users-permissions/users/me`, changes, {
			headers: token ? {
				Authorization: `Bearer ${token}`
			} : {}
		}).then(res => res.data).then(user => {
			dispatch({
				type: UPDATE_ME_SUCCESS,
				user
			});
			if (cb) cb();
		}).catch(err => {
			console.error("Error updating me: ", err);
			const errorMessage = errorResponse(err);
			dispatch(addLog({
				text: errorMessage,
				type: "error"
			}));
			dispatch({
				type: UPDATE_ME_FAIL,
				error: errorMessage
			});

		});
	}
}
