import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { CLEAR_LOGIN_ERROR } from "../../actions/session";
// import { NO_USER, CONFIRMED_USER, UNCONFIRMED_USER } from "../../constants";
// Authentication views.
import AccountConfirmation from "./AccountConfirmationView";
import AccountExistsConfirmed from "./AccountExistsConfirmedView";
import AccountExistsUnconfirmed from "./AccountExistsUnconfirmedView";
import Login from "./LoginView";
import SignupEmailCheckView from "./SignupEmailCheckView";
import ResetPasswordRequest from "./ResetPasswordRequestView";
import Signup from "./SignupView";
import ResetPassword from "./ResetPasswordView";

export const EMAIL_LOGIN = "EMAIL_LOGIN";
export const EMAIL_SIGNUP = "EMAIL_SIGNUP";
export const CHECK_EMAIL = "CHECK_EMAIL";
export const ACCOUNT_EXISTS_CONFIRMED = "ACCOUNT_EXISTS_CONFIRMED";
export const ACCOUNT_EXISTS_UNCONFIRMED = "ACCOUNT_EXISTS_UNCONFIRMED";
export const ACCOUNT_CONFIRMATION = "ACCOUNT_CONFIRMATION";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD = "RESET_PASSWORD";

const views = {
	[EMAIL_LOGIN]: Login,
	[EMAIL_SIGNUP]: Signup,
	[CHECK_EMAIL]: SignupEmailCheckView,
	[ACCOUNT_EXISTS_CONFIRMED]: AccountExistsConfirmed,
	[ACCOUNT_EXISTS_UNCONFIRMED]: AccountExistsUnconfirmed,
	[ACCOUNT_CONFIRMATION]: AccountConfirmation,
	[RESET_PASSWORD_REQUEST]: ResetPasswordRequest,
	[RESET_PASSWORD]: ResetPassword,
}

export default function LoginSignup({ onLoginSuccess, additionalUserData = {} }) {

	const dispatch = useDispatch();

	const {
		query: {
			resetPassword,
			code,
			email
		},
		loginError,
	} = useSelector(state => state.session);

	const [values, setValues] = useState({ email: email || "" });
	const [errors, setErrors] = useState({});

	const [view, setView] = useState((() => {
		if (resetPassword === "true" && code) {
			return RESET_PASSWORD;
		} else {
			return EMAIL_LOGIN;
		}
	})());

	function onChange(changes = {}) {
		setValues({
			...values,
			...changes
		});
	}

	function onViewChange(view) {
		dispatch({ type: CLEAR_LOGIN_ERROR });
		setView(view);
	}

	function onError(errorChanges = {}) {
		setErrors({
			...errors,
			...errorChanges
		});
	}

	function onInputChange(e) {
		onChange({
			[e.target.name]: e.target.value
		});
	}

	// Select view.
	const View = views[view];

	// Render it out.
	return <div className="login-signup">
		<SwitchTransition>
			<CSSTransition
				key={view}
				classNames="fade-switch"
				timeout={300}
			>
				<View {...{
					values,
					errors,
					onError,
					onInputChange,
					onChange,
					onViewChange,
					onLoginSuccess,
					additionalUserData
				}} />
			</CSSTransition>
		</SwitchTransition>
		{!!loginError && ![
			"Your account email is not confirmed"
		].includes(loginError) &&
			<div className="error">
				{loginError}
			</div>}
	</div>
}

export function InputField({ name, errors, values, onInputChange, attemptMade, ...props }) {

	const error = errors[name];
	const value = values[name] || "";

	return <div className={[
		"input-field",
		error ? "bad" : "good"
	].join(" ")}>
		<input
			type="text"
			{...props}
			value={value}
			name={name}
			onChange={onInputChange}
		/>
		{(!!error && attemptMade) && <>
			<br />
			<div className="error" style={{
				padding: 10
			}}>
				{error}
			</div>
		</>}
	</div>
}
