import { useMemo } from "react";
import { useSelector } from "react-redux";
import { idIfy } from "..";

export default function useSelectedQuantity(product, variant) {

	const items = useSelector(state => state.cart.items);
	const productId = idIfy(product);

	return useMemo(() => (
		items.reduce((acc, item) =>
			idIfy(item.product) === productId && (
				!variant || variant.title === item.variant
			) ? acc += item.quantity : acc,
			0
		)
	), [items, productId, variant]);
}
