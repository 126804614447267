import React, { useState, useRef, useEffect, useContext } from "react";
import { BrandContext } from "..";

export default function AccordionSection({ name, children, open, setOpen }) {

	const brand = useContext(BrandContext);

	const itemsContainer = useRef(null);
	const [finishedOpening, setFinishedOpening] = useState(open);
	const [itemsHeight, setItemsHeight] = useState(0);

	useEffect(() => {
		let to;
		if (open) {
			to = setTimeout(() => {
				setFinishedOpening(true);
			}, 500);
		} else {
			setFinishedOpening(false);
		}
		return () => {
			if (to) clearTimeout(to);
		}
	}, [open]);

	useEffect(() => {
		function checkHeight() {
			let height = itemsContainer.current.offsetHeight;
			if (height !== itemsHeight) setItemsHeight(height);
		}
		let interval = setInterval(checkHeight, 1000);
		checkHeight();
		window.addEventListener("resize", checkHeight);
		return () => {
			clearInterval(interval);
			window.removeEventListener("resize", checkHeight);
		}
    }, [open, itemsHeight, setItemsHeight]);
    
	return (
		<div
			className={`slct-accordion-section slct-accordion-section-${open ? "open" : "closed"} ${finishedOpening ? "slct-accordion-section-finished-opening" : ""}`}
			key={name}
		>
			<div
				className="slct-accordion-section-header pointer"
				onClick={() => setOpen(!open)}
			>
				<b className="default-font large" style={{ color: brand.color1 }}>{name}</b>
				<div className="item-count default-font large">
					{children.length} item{children.length === 1 ? "" : "s"}.
                </div>
			</div>
			<div className="items" style={{
				height: open ? itemsHeight : 0
			}}>
				<div ref={itemsContainer} className="items-inner">
					{children}
				</div>
			</div>
		</div>
	)


}
