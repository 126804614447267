export const ADD_LOG = "ADD_LOG";
export const REMOVE_LOG = "REMOVE_LOG";
export const SET_LOGS = "SET_LOGS";

/**
 * 
 * @param {Object} log 
 * @param {("error"|"info"|"warning"|"success")} log.type The type/severity of the log.
 * @param {String} log.text The content of the log.
 */
export function addLog(log) {
    return {
        type: ADD_LOG,
        log
    }
}

export function removeLog(logId) {
    return {
        type: REMOVE_LOG,
        logId
    }
}

export function setLogs(logs) {
    return {
        type: SET_LOGS,
        logs
    }
}