import { SHOPIFY_ASSET_ROOT } from "./constants";

export const audio = `${SHOPIFY_ASSET_ROOT}/audio.svg`;
export const tv = `${SHOPIFY_ASSET_ROOT}/tv.svg`;
export const wiFi = `${SHOPIFY_ASSET_ROOT}/wifi.svg`;
export const light = `${SHOPIFY_ASSET_ROOT}/light.svg`;
export const security = `${SHOPIFY_ASSET_ROOT}/security.svg`;
export const shades = `${SHOPIFY_ASSET_ROOT}/curtains.svg`;
export const heating = `${SHOPIFY_ASSET_ROOT}/heating.svg`;
export const smartHome = `${SHOPIFY_ASSET_ROOT}/smart.svg`;
export const chevronLeft = `${SHOPIFY_ASSET_ROOT}/chevron-left.svg`;
export const chevronRight = `${SHOPIFY_ASSET_ROOT}/chevron-right.svg`;
export const tick = `${SHOPIFY_ASSET_ROOT}/tick.svg`;
export const cross = `${SHOPIFY_ASSET_ROOT}/cross.svg`;
export const phone = `${SHOPIFY_ASSET_ROOT}/phone.svg`;
export const mail = `${SHOPIFY_ASSET_ROOT}/mail.svg`;
export const helpCircle = `${SHOPIFY_ASSET_ROOT}/help-circle.svg`;
export const plus = `${SHOPIFY_ASSET_ROOT}/plus.svg`;
export const minus = `${SHOPIFY_ASSET_ROOT}/minus.svg`;
export const search = `${SHOPIFY_ASSET_ROOT}/search.svg`;
export const searchWhite = `${SHOPIFY_ASSET_ROOT}/search-white.svg`;
export const developerIcon = `${SHOPIFY_ASSET_ROOT}/developer.svg`;
export const developerIconGray = `${SHOPIFY_ASSET_ROOT}/developer-grey.svg`;
export const codeIcon = `${SHOPIFY_ASSET_ROOT}/have-code.svg`;
export const noCodeIcon = `${SHOPIFY_ASSET_ROOT}/no-code.svg`;
export const codeIconGrey = `${SHOPIFY_ASSET_ROOT}/have-code-grey.svg`;
export const noCodeIconGrey = `${SHOPIFY_ASSET_ROOT}/no-code-grey.svg`;
export const checkout = `./assets/icon/checkout.svg`;
