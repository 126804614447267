import React, { useState } from "react";
import $ from "jquery";

function parse(description) {
    const result = {}
    let lastName;
    $(`<div>${description}</div>`).find(" > *").each(function () {
        let elem = $(this);
        if (elem.prop("nodeName") === "H2") {
            let name = elem.text().trim()
            lastName = name;
            result[name] = result[name] || [];
        } else if (lastName) {
            // If lastName is set push to it.
            result[lastName].push(elem)
        } else {
            return;
        }
    });

    Object.keys(result).forEach(resKey => {
        result[resKey] = format(result[resKey])
    });

    return result;
}

function format(elements = []) {
    return elements
        .map(elem => elem[0].outerHTML)
        .join("")
}

export default function ProductDescription({ description }) {

    const descriptionTabs = parse(description);
    const [selected, setSelected] = useState(Object.keys(descriptionTabs)[0]);

    return <div className="default-font description product-description-container">
        {Object.keys(descriptionTabs).length
            ?
            <>
                <div className="description-tabs">
                    {Object.keys(descriptionTabs).map(tab => {
                        let isActive = tab === selected;
                        return <div key={tab} className={"tab" + (isActive ? " active" : "")} onClick={() => {
                            if (!isActive) setSelected(tab)
                        }}>{tab}</div>
                    })}
                </div>
                <div 
                    className="description-content" 
                    dangerouslySetInnerHTML={{ __html: descriptionTabs[selected] }} 
                />
            </>
            :
            <div className="description-content" dangerouslySetInnerHTML={{ __html: description }}/>
        }
    </div>

}