import { useEffect, useState } from "react";

export const MOBILE = 576;
export const TABLET = 768;
export const DESKTOP = 992;

function getSizeClass(size) {
	if (size >= DESKTOP) {
		return DESKTOP;
	} else if (size >= MOBILE) {
		return TABLET;
	} else {
		return MOBILE;
	}
}

export function useDeviceSize() {

	const [size, setSize] = useState(getSizeClass(window.innerWidth));

	useEffect(() => {
		function onResize() {
			const newSize = getSizeClass(window.innerWidth);
			if (newSize !== size) setSize(newSize);
		}
		window.addEventListener("resize", onResize);
		return () => {
			window.removeEventListener("resize", onResize);
		}
	}, [size]);

	return { device: size };
}