import React from "react";
import { ACCOUNT_EXISTS_CONFIRMED, ACCOUNT_EXISTS_UNCONFIRMED, EMAIL_LOGIN, EMAIL_SIGNUP, InputField } from ".";
import { Button } from "../../assets";
import axios from "axios";
import { API_ROOT, NO_USER, UNCONFIRMED_USER } from "../../constants";
import { useDispatch } from "react-redux";
import { sendEmailConfirmation, SIGNUP_REQUEST_FAIL } from "../../actions/session";
import { errorResponse } from "../../helpers";
import { useState } from "react";

function checkEmail(email) {
	return axios.get(`${API_ROOT}/misc/get-user-email-status`, {
		params: { email }
	}).then(res => res.data);
}

export default function SignupEmailCheck({
	values,
	errors,
	onInputChange,
	onViewChange
}) {

	const dispatch = useDispatch();
	const [checking, setChecking] = useState(false);

	function onSubmit(e) {
		e.preventDefault();
		setChecking(true);
		checkEmail(values.email).then(result => {
			if (result === NO_USER) {
				onViewChange(EMAIL_SIGNUP);
			} else if (result === UNCONFIRMED_USER) {
				// Send a confirmation email.
				dispatch(sendEmailConfirmation(values.email));
				onViewChange(ACCOUNT_EXISTS_UNCONFIRMED);
			} else {
				onViewChange(ACCOUNT_EXISTS_CONFIRMED);
			}
		}).catch(error => {
			dispatch({
				type: SIGNUP_REQUEST_FAIL,
				error: errorResponse(error)
			});
			setChecking(false);
		});
	}

	return <div>
		<form onSubmit={onSubmit}>
			<h4>Create Account</h4>
			<div className="info">
				Enter the email you want to use to sign into the site.
			<br /><br />
			Make sure it's a genuine email.
		</div>
			<InputField
				name="email"
				placeholder="Email"
				values={values}
				errors={errors}
				onInputChange={onInputChange}
			/>
			<div className="input-field login-button">
				<Button loading={checking}>Continue</Button>
			</div>
		</form>
		<div className="info">
			Click the button below if you want to return to log in.
		</div>
		<div className="input-field login-button">
			<Button
				className=" default-font medium"
				onClick={e => onViewChange(EMAIL_LOGIN)}
			>Log in</Button>
		</div>
	</div>
}
