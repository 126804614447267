import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Button } from "../../assets";
// import { Link } from "react-router-dom";
import { AssetImage } from "../../assets";
import { useModal } from '../../assets/useModal';
import {
	API_ROOT,
	STRIPE_PUBLISHABLE_KEY
} from "../../constants";
import LoginSignup from '../LoginSignup';
import { loadStripe } from '@stripe/stripe-js';
import { couponLookup, couponRemove } from "../../actions/coupon";
import { errorResponse, idIfy, usePrevious } from "../../helpers";
import { addLog } from "../../actions/logger";


const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export default function CartTools({
	total
}) {
	const dispatch = useDispatch();

	const { coupon, deliveryNote, items, property, token, user } = useSelector(state => ({
		coupon: state.cart.coupon,
		deliveryNote: state.cart.deliveryNote,
		items: state.cart.items,
		property: state.cart.property,
		token: state.session.token,
		user: state.session.user
	}));
	const [couponCode, setCouponCode] = useState("");
	const [submitting, setSubmitting] = useState(false);
	const { setModal, openModal, closeModal } = useModal();

	const isLogged = Boolean(user);
	const wasLogged = usePrevious(isLogged);

	useEffect(() => {
		if (isLogged && !wasLogged && openModal) {
			closeModal();
			setTimeout(() => {
				setModal(null);
			}, 200);
		}
	}, [openModal, closeModal, isLogged, wasLogged, setModal]);

	const openLoginModal = () => {
		setModal(
			<LoginSignup />,
			null,
			{ closable: true, clearOnClose: true, className: 'auth-modal', contentClass: 'inner-content' }
		);
		openModal();
	}

	const handleCheckout = () => {
		if (!user || !user.email) {
			return openLoginModal();
		}

		if (!items.length || !property) return;

		// Prepare cart payload.
		const payload = {
			items: items.map(item => ({
				product: idIfy(item.product),
				installation: Boolean(item.installation),
				quantity: item.quantity,
				variant: item.variant
			})),
			coupon,
			deliveryNote,
			property,
		};

		// Blocking request
		setSubmitting(true);
		// Getting stripe session
		axios.post(`${API_ROOT}/orders/stripe-session`, payload, {
			headers: {
				authorization: `Bearer ${token}`
			}
		}).then(res => res.data).then(async res => {
			const stripe = await stripePromise;
			// Making payment
			await stripe.redirectToCheckout({
				sessionId: res.id
			}).catch(err => {
				console.log("Payment error:", err);
			}).finally(() => {
				setSubmitting(false);
			});
		}).catch(err => {
			const errorMessage = errorResponse(err);
			console.log("Error creating stripe session:", errorMessage);
			// Showing payment failed message.
			dispatch(addLog({
				type: "error",
				text: errorMessage
			}));
			setSubmitting(false);
		});
	}

	const handleCouponLookup = () => {
		if (!items.length || !property || !couponCode) return;

		dispatch(couponLookup(couponCode, error => {
			if (error) {
				dispatch(addLog({
					type: "error",
					text: error
				}));
			} else {
				dispatch(addLog({
					type: "success",
					text: 'Coupon code successfully applied.'
				}));
			}
		}));
	}

	const handleCouponRemove = () => {
		if (!coupon || !coupon.code) return;

		setCouponCode("");
		dispatch(couponRemove(() => {
			dispatch(addLog({
				type: "success",
				text: 'Coupon code successfully removed.'
			}));
		}));
	}

	return <div className="checkout-controls">
		<div className="code">
			<div className="property-code-checkout">
				{
					coupon && coupon.code
						?
						<>
							<input
								disabled={true}
								className="default-font medium"
								type="text"
								placeholder="Offer/rep code"
								value={coupon.code}
							/>
							<Button
								loading={false}
								type="submit"
								style={{ width: '50%' }}
								onClick={handleCouponRemove}
							>
								Remove
							</Button>
						</>
						:
						<>
							<input
								className="default-font medium"
								type="text"
								placeholder="Offer/rep code"
								value={couponCode}
								onChange={e => setCouponCode(e.target.value)}
							/>
							<Button
								loading={false}
								type="submit"
								style={{ width: '50%' }}
								onClick={handleCouponLookup}
							>
								Enter
							</Button>
						</>
				}
			</div>
			{/* <Link to="/account" className="default-font medium checkout-link"> */}
			<div className="checkout-link">
				<Button
					disabled={submitting}
					loading={false}
					className="checkout-button"
					type="submit"
					onClick={handleCheckout}
				>
					Checkout - £{(total / 100).toFixed(2)}
					<div>
						<AssetImage src={'./icon/cart.svg'} size={40} className="checkout-icon"></AssetImage>
					</div>
				</Button>
			</div>
			{/* </Link> */}

		</div>
	</div>
};
