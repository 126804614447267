import React, { useState, useRef, useEffect } from "react";
import { ImgIcon } from "../assets";
import { chevronLeft, chevronRight } from "../icons";
import { useModal } from "../assets/useModal";
import { useDeviceSize, MOBILE, TABLET } from "../helpers/hooks/useDeviceSize";

/**
 * 
 * @param {object} items - { full, small } 
 */

export default function Carousel({ items }) {

    const scrollContainer = useRef();
    const [position, setPosition] = useState(0);
    const screenSize = useDeviceSize();
    const carouselItemCount = calculateItemCount(screenSize.device);
    const canGoLeft = position > 0;
    const canGoRight = position < (items.length - carouselItemCount);

    const { setModal, openModal } = useModal();

    useEffect(() => {
        scrollContainer.current.scroll({
            left: (scrollContainer.current.offsetWidth / carouselItemCount) * position,
            behavior: 'smooth'
        });
    }, [position, carouselItemCount, screenSize]);

    function imageModal(position) {
        setModal(<ImageGallery key={position} images={items.map(item => item.full)} startPosition={position}/>);
        openModal();
    }

    return <div className="carousel">
        <div className={"arrow left shadow pointer" + (!canGoLeft ? " disabled" : "")} onClick={() => {
            if (canGoLeft) setPosition(position - 1);
        }}>
            <ImgIcon
                size={20}
                src={chevronLeft}
                cursor={canGoLeft ? "pointer" : null}
            />
        </div>
        <div className="items" ref={scrollContainer}>
            {items.map((item, position) => (
                <div
                    className="item"
                    key={item.full}
                    style={{
                        backgroundImage: `url('${item.small}')`,
                        width: (100 / carouselItemCount) + "%",
                    }}
                    onClick={() => {
                        imageModal(position)
                    }}
                />
            ))}
        </div>
        <div className={"arrow right shadow pointer" + (!canGoRight ? " disabled" : "")} onClick={() => {
            if (canGoRight) setPosition(position + 1);
        }}>
            <ImgIcon
                size={20}
                src={chevronRight}
                cursor={canGoRight ? "pointer" : null}
            />
        </div>
    </div>
};

export function ImageGallery({ images = [], startPosition = 0 }) {

    const [position, setPosition] = useState(startPosition);

    function change(pos) {
        let newPosition = pos;
        if (pos < 0) {
            newPosition = images.length - 1;
        } else if (pos > images.length - 1) {
            newPosition = 0;
        }
        setPosition(newPosition);
    }

    return <div className="image-gallery">
        <div className="arrow left shadow pointer" onClick={() => change(position - 1)}>
            <ImgIcon
                size={27}
                src={chevronLeft}
                cursor="pointer"
            />
        </div>
        <img src={images[position]} alt="carousel item" style={{ width: "100%" }} />
        <div className="arrow right shadow pointer" onClick={() => change(position + 1)}>
            <ImgIcon
                size={27}
                src={chevronRight}
                cursor="pointer"
            />
        </div>
    </div>

}

function calculateItemCount(size) {
    if (size === MOBILE) {
        return 1;
    } else if (size === TABLET) {
        return 2;
    } else {
        return 3;
    }
};