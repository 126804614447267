import { initialState } from ".";
import { PROPERTY_LOOKUP_SUCCESS } from "../actions/property";
import { DEVELOPMENT_LOOKUP_FAIL, DEVELOPMENT_LOOKUP_START, DEVELOPMENT_LOOKUP_SUCCESS } from "../actions/development";

export default function (state = initialState.development, action) {
	switch (action.type) {

		case DEVELOPMENT_LOOKUP_START:
			return {
				...state,
				lookupError: null,
				loading: true
			}
		case DEVELOPMENT_LOOKUP_FAIL:
			return {
				...state,
				lookupError: action.error,
				loading: false
			}
		case DEVELOPMENT_LOOKUP_SUCCESS:
			return {
				...state,
				loading: false,
				...action.development
			}

		case PROPERTY_LOOKUP_SUCCESS:
			return {
				...state,
				loading: false,
				...action.property.development
			}

		default:
			return state
	}
}