import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { API_ROOT } from "../constants";
import { useOnClickOutside } from "../helpers/hooks/useClickOutside";
import { search } from "../icons";
import VerticalExpander from "./VerticalExpander";

export default function PropertyLookupField({ onSelect }) {

    const ref = useRef(null);
    const [open, setOpen] = useState(false);
    const [inputVal, setInputVal] = useState(null);
    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        let to = setTimeout(async () => {
            setError(null);
            if (!inputVal) {
                setItems([]);
            } else {
                let postcode = encodeURIComponent(inputVal.replace(/\s+/g, ''));
                // Clearing errors before making new request
                axios.get(`${API_ROOT}/properties/lookup?postcode=${postcode}`)
                    .then(res => res.data).then(results => {
                        setItems(results);
                    }).catch(err => {
                        console.error("Error getting properties: ", err);
                        setError("Property lookup error.")
                    })
            }
        }, 300);
        return () => clearTimeout(to)
    }, [inputVal]);

    useOnClickOutside(ref, e => {
        if (open) setOpen(false)
    });

    return (
        <div ref={ref} className="property-lookup">
            <div className="property-lookup-input-wrapper">
                <input
                    onFocus={() => {
                        if (!open) setOpen(true)
                    }}
                    className="default-font medium"
                    placeholder="Enter your property's postcode"
                    type="text"
                    value={inputVal || ""}
                    onChange={e => {
                        setInputVal(e.target.value);
                    }}
                />
                <img className="property-lookup-svg" src={search} alt="search icon" />
            </div>
            <VerticalExpander
                open={!error && open}
            >
                {<ul className="lookup-results">
                    {items.length
                        ? items.map(item => {
                            return (
                                <li
                                    key={item.id}
                                    className="lookup-item property-item"
                                    onClick={() => {
                                        setOpen(false);
                                        onSelect(item);
                                    }}
                                >
                                    {[item.line1, item.line2, item.postcode, item.town].filter(Boolean).join(", ")}
                                </li>
                            )
                        })
                        : <li className="lookup-item" key="no-results">No results</li>
                    }
                </ul>}
            </VerticalExpander>
            {Boolean(error) && <div className="error">{error}</div>}
        </div>
    )
}